<template>
  <div style="min-height: 100vh; overflow: hidden">
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
    <logopage /></transition>
    <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
    <div class="columns is-centered mb-0">
      <div
        v-if="$root.selectedLanguage === `en`"
        class="column"
        style="text-align: center"
      >
        <img
          src="../assets/images/header-img/VedioCall.png"
          alt="FinanceInfo-header-img"
          class="page-header-img"
        />
      </div>
    </div></transition>
    <div class="columns is-centered mb-0">
      <div class="column is-half ps-5" style="text-align: center">
        <p class="subhead-line is-size-4">
          <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
          <img
            src="../assets/images/icons/VedioCallImg.png"
            alt="home_dolar_img"
            width="15%"
          /></transition>
        </p>
        <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
        <p class="headline-font" style="line-height: 42px">
          {{ $root.selectedLanguageData.video_call_details }}
        </p></transition>
        <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
        <p class="specific-line">
          {{ $root.selectedLanguageData.select_date_time }}
        </p></transition>
      </div>
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <div class="form-body mt-3">
          <transition appear
                @before-enter="$animations.beforeEnterBelow"
                @enter="$animations.enterBelow"
    >
          <div class="box form-padding pb-5" style="padding-top: 0% !important">            
            <p class="has-text-centered  pt-4 mb-4 astric-font-size">
                <span class="has-text-danger  mr-2">{{
                  $root.selectedLanguageData.note
                }}</span><b class="has-text-danger">*</b><span class="text-transparency">
               Indicates mandatory fields that are required</span>
            </p>
            <div class="columns is-7 is-variable">
              <div class="column">
                <ValidationProvider
                  name="Date"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="'custom_float_label_date'"
                  >
                    <template #label
                      >{{
                        $root.selectedLanguageData
                          .preferred_date_for_video_call
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <b-datepicker
                        placeholder="DD/MM/YY"
                        :unselectable-days-of-week="[0, 6]"
                        :min-date="minDate"
                        :max-date="maxDate"
                        :append-to-body="true"
                        :years-range="[-100, 100]"
                        :unselectable-dates="unselectableDates"
                        rounded
                        v-model="
                          kycFormStore.formData.preferred_date_for_video_call
                        "
                      >
                      </b-datepicker>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/Calendar.png"
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!--              time-->
              <div class="column">
                <ValidationProvider
                  name="Time"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.preferred_time_for_video_call.trim() ===
                        '' && !isTimeInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.preferred_time
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <b-select
                        expanded
                        rounded
                        v-model="
                          kycFormStore.formData.preferred_time_for_video_call
                        "
                        :class="
                          kycFormStore.formData
                            .preferred_time_for_video_call === '' &&
                          !isTimeInputFocus
                            ? 'arrow-up-before'
                            : 'arrow-up'
                        "
                        @focus="
                          () => {
                            isTimeInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isTimeInputFocus = false;
                          }
                        "
                        @input="
                          () => {
                            isPastTimeSelected = false;
                          }
                        "
                      >
                        <option
                          v-for="time_slot in filteredTimeSlots"
                          :key="time_slot.time"
                          :value="time_slot.time"
                          :disabled="time_slot.disable"
                        >
                          {{ time_slot.time }}
                        </option>
                      </b-select>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/clarity_clock-line.png"
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="mt-5">
              <p class="mb-4 note-line">
                <span class="has-text-danger has-text-weight-semibold">{{
                  $root.selectedLanguageData.note
                }}</span>
                {{ $root.selectedLanguageData.vedio_note }}
              </p>
            </div>
          </div></transition>
          <!-- button -->
          <transition appear
                @before-enter="$animations.beforeEnterBelow"
                @enter="$animations.enterBelow"
    >
          <div class="is-flex is-flex-direction-row-reverse mt-2 mb-4">
            <b-button
              class="is-primary is-rounded specific-continue-button-en py-5"
              :loading="continueBtnLoading"
              @click="updateFormData()"
              :disabled="invalid"
              >{{ $root.selectedLanguageData.continue }}
            </b-button>
            <b-button
              class="is-rounded specific-back-button-en py-5"
              @click="$router.push('additionalService')"
              >{{ $root.selectedLanguageData.back }}
            </b-button>
          </div></transition>
        </div>
      </form>
    </ValidationObserver>
    <!-- <GetHelp /> -->
  </div>
</template>

<script>
import logopage from "./CompanyTopLogo.vue";
import NetworkManager from "@/utils/networkManager";
import { ApiRequestManager } from "@/utils/networkManager/apiRequestManager";
import { useKycFormStore } from "@/stores/KycFormStore";
import { BuefyHelper } from "@/heplers/buefyHelper";
// import GetHelp from "./GetHelp.vue";

export default {
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },
  components: {
    logopage,
    // GetHelp,
  },
  data() {
    return {
      isTimeInputFocus: false,
      today: new Date(),
      nextAvailableDate: new Date(),
      minDate: new Date(),
      continueBtnLoading: false,
      maxDate: new Date(),
      time_slots: [],
      unselectableDates: [],
      unselectableDatesArray: [],
      isPastTimeSelected: false,
      isAllSlotsDisabled: false,
      datecannotpick:false,
    };
  },
  computed: {
    filteredTimeSlots() {
      return this.time_slots.filter(time_slot => !time_slot.disable);
    },
  },
  mounted() {
    this.getTimeSlots();

    if (
      new Date(this.kycFormStore.formData.preferred_date_for_video_call) <
      new Date()
    ) {
      this.minDate = new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate()
      );
    }
    this.maxDate = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate() + 14
    );
  },
  watch:{
    'kycFormStore.formData.preferred_date_for_video_call' : function()
    {
      this.getTimeSlots();
    }
  },
  methods: {
    ifweekend:function(){
      const comp = this
      console.log("In Weekend")

      comp.datecannotpick =false
      const prefday= comp.kycFormStore.formData.preferred_date_for_video_call
      const monday = new Date(prefday)
      let nextday =new Date()
      if(prefday.getDay() === 6)
        {
          console.log("Saturday")
          nextday = new Date(
            monday.getFullYear(),
            monday.getMonth(),
            monday.getDate() + 2
          );
        }
        else if(prefday.getDay() === 0)
        {
          console.log("Sunday")
          nextday = new Date(
            monday.getFullYear(),
            monday.getMonth(),
            monday.getDate() + 1
          );
        }
        nextday.setHours(0,0,0,0);
        console.log("Monday selected :",nextday)
        comp.kycFormStore.formData.preferred_date_for_video_call = nextday
    },

    ifNoPreferedday:function(){
      console.log("No Prefered day")
      const comp= this;

      comp.datecannotpick =false

      const startOfToday = new Date();
      startOfToday.setHours(0,0,0,0);
 
      console.log("preferred_date_for_video_call Date set to startOfToday",startOfToday)
      comp.kycFormStore.formData.preferred_date_for_video_call = startOfToday;

      const todayasdata = new Date(startOfToday.getFullYear(),
          startOfToday.getMonth(),
          startOfToday.getDate() + 1).toISOString().split("T")[0] + "T00:00:00";
      console.log("startOfToday as unselectabledate -", todayasdata);



      // Check if today is an unselectable day
      if (comp.unselectableDatesArray.includes(todayasdata)) {
        console.log("todayasdata is in unselectable days");
        const nextAvailableDate = new Date(todayasdata);
        console.log("next Available Date", nextAvailableDate);
        const unselectableday = nextAvailableDate.toISOString().split("T")[0] + "T00:00:00"
        console.log("unselectableday",unselectableday);
        // Find the next available day
        while (comp.unselectableDatesArray.includes(unselectableday)) 
        {
          nextAvailableDate.setDate(nextAvailableDate.getDate() + 1);
        }

        // Set the next available day as the selected date
        nextAvailableDate.setHours(0,0,0,0)
        console.log("preferred_date_for_video_call Date set to nextAvailableDate",nextAvailableDate)
        comp.kycFormStore.formData.preferred_date_for_video_call = nextAvailableDate;
        comp.isDateOkay = true;
      } 
      comp.time_slots.forEach((time_slot) => {
        if (time_slot.time == comp.kycFormStore.formData.preferred_time_for_video_call && time_slot.disable) 
        {
          comp.isPastTimeSelected = true;
        }
      });

    },

    ifPreferedday: function(){
      console.log("Prefered day available")
      const comp= this;
      comp.datecannotpick =false

      const selecteddate =  comp.kycFormStore.formData.preferred_date_for_video_call
      console.log("Selected date : ",selecteddate)
      const Tommorow = new Date(
        selecteddate.getFullYear(),
        selecteddate.getMonth(),
        selecteddate.getDate() + 1
      );
      console.log("Start- of- Nextday :", Tommorow);

      comp.kycFormStore.formData.preferred_date_for_video_call = Tommorow;

      BuefyHelper.showToastMessage(
        "Sorry. No timeslots available for today",
        "is-danger"
      );

      const todayasdata =
      new Date(Tommorow.getFullYear(),
      Tommorow.getMonth(),
      Tommorow.getDate() + 1).toISOString().split("T")[0] + "T00:00:00";
      console.log("today as data--", todayasdata);

      // Check if today is an unselectable day
      if (comp.unselectableDatesArray.includes(todayasdata)) {
        console.log("No timeslots available for today");
        const nextAvailableDate = new Date(Tommorow);
        console.log("next Available Date", nextAvailableDate);


        // const todayasdata =
        // new Date(Tommorow.getFullYear(),
        // Tommorow.getMonth(),
        // Tommorow.getDate() + 1).toISOString().split("T")[0] + "T00:00:00";
        // console.log("today as data--", todayasdata);
        // Find the next available day
        console.log("before while loop",todayasdata)
        if(todayasdata.getDay() +1 != 6)
        {
          while (
            comp.unselectableDatesArray.includes(todayasdata)
          ) {
            console.log("running while loop")
            nextAvailableDate.setDate(nextAvailableDate.getDate() + 1);
          }
        }

        // Set the next available day as the selected date
        comp.kycFormStore.formData.preferred_date_for_video_call =
          nextAvailableDate;
        comp.isDateOkay = true;
      } 
      // else {
      //   // Set today as the preferred date for the video call
      //   comp.kycFormStore.formData.preferred_date_for_video_call = startOfToday;
      // }

      comp.time_slots.forEach((time_slot) => {
        if (time_slot.time == comp.kycFormStore.formData.preferred_time_for_video_call && time_slot.disable )     
        {
          comp.isPastTimeSelected = true;
        }
      });
    },

    checkDateOkay:function(){
      const comp= this
      if(comp.kycFormStore.formData.preferred_date_for_video_call != "")
      {
        const datadate = comp.kycFormStore.formData.preferred_date_for_video_call
        console.log("preferred_date_for_video_call",datadate)
        const todaytime = comp.today.setHours(0,0,0,0)
        const selecteddaytime = comp.kycFormStore.formData.preferred_date_for_video_call.setHours(0,0,0,0)
        console.log()
        const selecteddate = new Date(datadate.getFullYear(),
              datadate.getMonth(),
              datadate.getDate() + 1).toISOString().split("T")[0] + "T00:00:00";
        console.log("Check selected date for unselectability : ",selecteddate)
        if (comp.unselectableDatesArray.includes(selecteddate))
        {
          //console.log("date cannot pick")
          comp.datecannotpick= true
          console.log("date cannot pick :", comp.datecannotpick)
        }
        if(todaytime > selecteddaytime)
        {
          console.error("PAST DAY DETECTED!.")
          comp.datecannotpick= true
        }
      }
    },

    getTimeSlots: function () {
      const comp = this;
      const data = {
        today: true,
      };
      if (
        comp.kycFormStore.formData.preferred_date_for_video_call.toDateString() !=
        new Date().toDateString()
      ) {
        data.today = false;
      }
      NetworkManager.apiRequest(
        "api/KycForm/getVideoCallDateAndTime",
        data,
        function (response) {
          if (response.statusCode == 200) {
            comp.time_slots = response.data.time_slots;
            comp.unselectableDatesArray = response.data.leave_dates;
            comp.unselectableDatesArray.forEach((element) => {
              console.log("Unselectable Date:", element)
              comp.unselectableDates.push(new Date(element))
            });
            comp.isAllSlotsDisabled = response.data.AllSlotsDisabled;
            console.log("Data recievied",response.data)

            comp.checkDateOkay(); 

            if(comp.isAllSlotsDisabled)
            {
              console.log("ALL SLOTS DISABLED")
            }
            if(comp.kycFormStore.formData.preferred_date_for_video_call != "")
            {
              console.log("checking preferred_date_for_video_call",comp.kycFormStore.formData.preferred_date_for_video_call)
            }
            if ( comp.kycFormStore.formData.preferred_date_for_video_call == "" && !comp.isAllSlotsDisabled) {
              console.log("no pre selected date detected")
              comp.ifNoPreferedday()
            }
            if(comp.kycFormStore.formData.preferred_date_for_video_call.getDay() === 6 || comp.kycFormStore.formData.preferred_date_for_video_call.getDay() === 0)
            {
              console.log("weekend triggered")
              comp.ifweekend()
            }
            if ((comp.kycFormStore.formData.preferred_date_for_video_call != "" && comp.isAllSlotsDisabled) || comp.datecannotpick) {
              console.log("Running ifPreferedday function")
              comp.ifPreferedday()   
            }
          } 
          else 
          {
            BuefyHelper.showToastMessage(
              "Failed to get video call date and time",
              "is-danger"
            );
          }
        },false,true);
    },

    updateFormData: function () {
      const comp = this;

      comp.time_slots.forEach((time_slot) => {
        if (time_slot.time == comp.kycFormStore.formData.preferred_time_for_video_call && time_slot.disable )     
        {
          comp.isPastTimeSelected = true;
        }
      })

      var dateNow = new Date(
        comp.today.getFullYear(),
        comp.today.getMonth(),
        comp.today.getDate()
      );
      console.log(
        comp.kycFormStore.formData.preferred_date_for_video_call.getDay() ===
          6 ||
          comp.kycFormStore.formData.preferred_date_for_video_call.getDay() ===
            0,
        "llllll",
        comp.kycFormStore.formData.preferred_date_for_video_call.getDay()
      );
      var selectedDate = new Date(
        comp.kycFormStore.formData.preferred_date_for_video_call.getFullYear(),
        comp.kycFormStore.formData.preferred_date_for_video_call.getMonth(),
        comp.kycFormStore.formData.preferred_date_for_video_call.getDate()
      );
      if (
        dateNow > selectedDate ||
        comp.kycFormStore.formData.preferred_date_for_video_call.getDay() ===
          6 ||
        comp.kycFormStore.formData.preferred_date_for_video_call.getDay() === 0
      ) {
        BuefyHelper.showToastMessage(
          "Please select a valid Preferred Date",
          "is-danger"
        );
      } else if (comp.isPastTimeSelected) {
        BuefyHelper.showToastMessage(
          "Please select a valid Preferred Time",
          "is-danger"
        );
      } else {
        comp.continueBtnLoading = true;

        ApiRequestManager.updateLastStep(
          comp.kycFormStore.formData.landing_token,
          "5",
          "/vedioCallDetails",
          comp.kycFormStore.formData,
          function (status) {
            if (status) {
              comp.$router.push("documentId");
              comp.continueBtnLoading = false;
            } else {
              comp.continueBtnLoading = false;
            }
          }
        );
      }
      console.log(dateNow > selectedDate);
    },
  },
};
</script>
<style></style>
