import { render, staticRenderFns } from "./App.vue?vue&type=template&id=852847f2&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=852847f2&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./assets/js/webRTCAdapter/webRTCAdapter.js?vue&type=custom&index=0&blockType=script2&issuerPath=D%3A%5CPayMedia%5CRepository%5Cpaymedia-ekyc-kyc-form%5Csrc%5CApp.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports