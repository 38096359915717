<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
    <logopage /></transition>
    <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
    <div class="columns is-centered mb-0">
      <div
        v-if="$root.selectedLanguage === `en`"
        class="column"
        style="text-align: center"
      >
        <img
          src="../assets/images/header-img/Doccument_page.png"
          alt="Doccument-img"
          class="page-header-img"
        />
      </div>
    </div></transition>

    <div class="columns is-centered mb-0">
      <div class="column is-half pt-2 ps-5" style="text-align: center">
        <p class="subhead-line is-size-4" style="line-height: 20px">
          <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
          <img
            src="../assets/images/icons/camera.png"
            alt="camera"
            width="15%"
          /></transition>
        </p>
        <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
        <p class="headline-font" style="line-height: 42px">
          {{ $root.selectedLanguageData.documents }}
        </p></transition>
        <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
        <p class="specific-line">
          <!-- {{ $root.selectedLanguageData.dear }}
          {{ kycFormStore.formData.calling_name || "Charith" }}, -->
          {{ $root.selectedLanguageData.kindly_attach_the_following }}
        </p></transition>
      </div>
    </div>
    <ValidationObserver>
      <form @submit.prevent="onSubmit">
        <div class="form-body mt-3">
          <div class="box form-padding pb-5" style="padding-top: 0% !important">            
            <p class="has-text-centered  pt-4 mb-4 astric-font-size">
                <span class="has-text-danger  mr-2">{{
                  $root.selectedLanguageData.note
                }}</span><b class="has-text-danger">*</b><span class="text-transparency">
               Indicates mandatory fields that are required</span>
              </p>            <!-- NIC Image -->
            <div class="mb-6">
              <label>
                {{ $root.selectedLanguageData.uploadimagesofNIC }}
              </label>
              <p class="note-line">
                <span class="has-text-danger has-text-weight-semibold">{{
                  $root.selectedLanguageData.note
                }}</span>
                {{
                  $root.selectedLanguageData
                    .noteUploadthenationalidentitycardimagesMakesurethereisnoblurorglare
                }}
              </p>

              <div class="columns mt-1 is-7 is-variable">
                <div class="column is-one-two">
                  <label
                    :class="`${
                      $root.selectedLanguage === `ta`
                        ? `ta-label`
                        : $root.selectedLanguage === `si`
                        ? `si-label`
                        : ``
                    }`"
                    >{{ $root.selectedLanguageData.nicfrontsideimage
                    }}<span class="has-text-danger">*</span>
                  </label>
                  <b-field
                    :class="{
                      'has-name': !!kycFormStore.productImages.nic_front_image,
                    }"
                  >
                    <ValidationProvider
                      :rules="{ required: true, size: 1024, image: true }"
                      name="image"
                      v-slot="{ errors }"
                    >
                      <image-capture-component
                      :allowPdf="true"
                      v-if="kycFormStore.formData.idType === 'ONIC'"
                      @on-captured="onNicCaptured" :crop_ratio="null"
                      file_name="nic_front_image"
                      :selectedImageURL="NicFrontImageURL"
                      :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                      :captureButtonLabel="$root.selectedLanguageData.capture"
                      /> 
                      <image-capture-component
                      :allowPdf="true"
                      v-else-if="kycFormStore.formData.idType === 'NNIC'"
                      @on-captured="onNicCaptured" :crop_ratio="null"
                      file_name="nic_front_image"
                      :selectedImageURL="NicFrontImageURL"
                      :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                      :captureButtonLabel="$root.selectedLanguageData.capture"
                      />
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>

                <div class="column is-one-two">
                  <label
                    :class="`${
                      $root.selectedLanguage === `ta`
                        ? `ta-label`
                        : $root.selectedLanguage === `si`
                        ? `si-label`
                        : ``
                    }`"
                    >{{ $root.selectedLanguageData.nicbacksideimage
                    }}<span class="has-text-danger">*</span>
                  </label>
                  <b-field
                    :class="{
                      'has-name': !!kycFormStore.productImages.nic_back_image,
                    }"
                  >
                    <ValidationProvider
                      :rules="{ required: true, size: 1024, image: true }"
                      name="image"
                      v-slot="{ errors }"
                    >
                      <image-capture-component 
                      :allowPdf="true"
                      @on-captured="onNicBackCaptured"
                      :crop_ratio="null" file_name="nic_back_image"
                      :selectedImageURL="NicBackImageURL"
                      :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                      :captureButtonLabel="$root.selectedLanguageData.capture"/>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
            </div>

            <!-- passport image -->
            <div
              class="mt-4 mb-6"
              v-if="kycFormStore.formData.customer_passport_no"
            >
              <label
                >{{ $root.selectedLanguageData.uploadimagesofpassport }}
              </label>
              <p class="mb-4 note-line">
                <span class="has-text-danger has-text-weight-semibold">{{
                  $root.selectedLanguageData.note
                }}</span>
                {{
                  $root.selectedLanguageData
                    .noteUploadthefrontpageofyourpassportMakesurethereisnoblurorglare
                }}
              </p>

              <div class="columns mt-1 is-7 is-variable">
                <div class="column is-one-two">
                  <label
                    >{{ $root.selectedLanguageData.bio_page_img
                    }}<span
                      class="has-text-danger"
                      v-if="
                        kycFormStore.formData.residentship ===
                        `Out of Sri Lanka`
                      "
                      >*</span
                    >
                  </label>
                  <b-field
                    :class="{
                      'has-name': !!kycFormStore.productImages.passport_image,
                    }"
                  >
                    <ValidationProvider
                      :rules="{ required: true, size: 1024, image: true }"
                      name="image"
                      v-slot="{ errors }"
                    >
                      <image-capture-component 
                      :allowPdf="true"
                      @on-captured="onPassportCaptured"
                      :crop_ratio="null" file_name="passport_image"
                      :selectedImageURL="PassportImageURL"
                      :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                      :captureButtonLabel="$root.selectedLanguageData.capture"/>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>

                <div class="column is-one-two">
                  <label
                    >{{ $root.selectedLanguageData.alteration_page_image
                    }}<span
                      class="has-text-danger"
                      v-if="
                        kycFormStore.formData.residentship ===
                        `Out of Sri Lanka`
                      "
                      >*</span
                    >
                  </label>
                  <b-field
                    :class="{
                      'has-name': !!kycFormStore.productImages.passport_image_2,
                    }"
                  >
                    <ValidationProvider
                      :rules="{ required: true, size: 1024, image: true }"
                      name="image"
                      v-slot="{ errors }"
                    >
                      <image-capture-component
                      :allowPdf="true"
                      @on-captured="onPassportSecoundCaptured" 
                      :crop_ratio="null"
                      file_name="passport_image"
                      :selectedImageURL="PassportImage2URL"
                      :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                      :captureButtonLabel="$root.selectedLanguageData.capture"/>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <!-- entry_stamp_page -->
              <div class="column is-one-two">
                <label
                  >{{ $root.selectedLanguageData.entry_stamp_page_image
                  }}<span
                    class="has-text-danger"
                    v-if="
                      kycFormStore.formData.residentship === 'Out of Sri Lanka'
                    "
                    >*</span
                  >
                </label>
                <div class="columns is-centered">
                  <div class="column is-two-thirds">
                    <div class="has-text-centered">
                      <b-field
                        :class="{
                          'has-name':
                            !!kycFormStore.productImages.stamp_page_image,
                        }"
                      >
                        <ValidationProvider
                          :rules="{ required: true, size: 1024, image: true }"
                          name="image"
                          v-slot="{ errors }"
                        >
                          <image-capture-component
                          :allowPdf="true"
                          @on-captured="onStampPageCaptured" 
                          :crop_ratio="null"
                          file_name="passport_image"
                          :selectedImageURL="StampImageURL"
                          :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                          :captureButtonLabel="$root.selectedLanguageData.capture"/>
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>

              <!-- visa image -->
              <div class="column is-one-two">
                <label
                  >{{ $root.selectedLanguageData.visa_image
                  }}<span
                    class="has-text-danger"
                    v-if="
                      kycFormStore.formData.residentship === 'Out of Sri Lanka'
                    "
                    >*</span
                  >
                </label>
                <p class="mb-4 note-line">
                  <span class="has-text-danger has-text-weight-semibold">{{
                    $root.selectedLanguageData.note
                  }}</span>
                  {{
                    $root.selectedLanguageData
                      .noteUploadtheVisaImageMakesurethereisnoblurorglare
                  }}
                </p>
                <div class="columns is-centered">
                  <div class="column is-two-thirds">
                    <div class="has-text-centered">
                      <b-field
                        :class="{
                          'has-name': !!kycFormStore.productImages.visa_image,
                        }"
                      >
                        <ValidationProvider
                          :rules="{ required: true, size: 1024, image: true }"
                          name="image"
                          v-slot="{ errors }"
                        >
                          <image-capture-component 
                          :allowPdf="true"
                          @on-captured="onVisaCaptured"
                          :crop_ratio="null" file_name="visa_image"
                          :selectedImageURL="VisaImageURL"
                          :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                          :captureButtonLabel="$root.selectedLanguageData.capture"/>
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- selfie image -->
            <div>
              <label
                :class="`${
                  $root.selectedLanguage === `ta`
                    ? `ta-label`
                    : $root.selectedLanguage === `si`
                    ? `si-label`
                    : ``
                }`"
                >{{ $root.selectedLanguageData.uploadyourselfieimage
                }}<span class="has-text-danger">*</span>
              </label>

              <p class="mb-4 note-line">
                <span class="has-text-danger has-text-weight-semibold">{{
                  $root.selectedLanguageData.note
                }}</span>
                {{
                  $root.selectedLanguageData
                    .noteUploadtheclearimageofyourselfMakesurethereisnoblurorglare
                }}
              </p>
              <div class="columns is-centered">
                <div class="column is-two-thirds">
                  <div class="has-text-centered">
                    <b-field
                        style="display: flex; justify-content: center"
                      :class="{
                        'has-name': !!kycFormStore.productImages.selfie_image,
                      }"
                    >
                      <ValidationProvider
                        :rules="{ required: true, size: 1024, image: true }"
                        name="image"
                        v-slot="{ errors }"
                      >

                        <!-- old component -->
                        <!-- >
                          <image-capture-component
                          :allowPdf="true"
                          @on-captured="onSelfieCaptured"
                          :crop_ratio="null" file_name="selfie_image"
                          :selectedImageURL="SelfieImageURL"
                          :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                          :captureButtonLabel="$root.selectedLanguageData.capture"
                          :use-front-camera="true"/>
                        < -->

                        <!-- new selfie capture component -->
                        <selfie-capture-component
                            :selected-image-url="SelfieImageURL"
                            file-name="selfie_image"
                            @on-captured="onSelfieCaptured"
                           />

                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>

            <!-- signature_image -->
            <div class="mt-6">
              <label
                :class="`${
                  $root.selectedLanguage === `ta`
                    ? `ta-label`
                    : $root.selectedLanguage === `si`
                    ? `si-label`
                    : ``
                }`"
                >{{ $root.selectedLanguageData.uploadyoursignatureimage
                }}<span class="has-text-danger">*</span></label
              >
              <p class="mb-4 note-line">
                <span class="has-text-danger has-text-weight-semibold">{{
                  $root.selectedLanguageData.note
                }}</span>
                {{
                  $root.selectedLanguageData
                    .notepleasesignyoursignatureonawhitepaperandthentakeanimageofit
                }}
              </p>
              <div class="columns is-centered">
                <div class="column is-two-thirds">
                  <div class="has-text-centered">
                    <b-field
                      :class="{
                        'has-name': !!kycFormStore.productImages.sign_image,
                      }"
                    >
                      <ValidationProvider
                        :rules="{ required: true, size: 1024, image: true }"
                        name="image"
                        v-slot="{ errors }"
                      >
                        <image-capture-component
                        @on-captured="onSignatureCaptured" :crop_ratio="null"
                        :allowPdf="false"
                        file_name="sign_image"
                        :selectedImageURL="SignImageURL"
                        :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                        :captureButtonLabel="$root.selectedLanguageData.capture"/>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- Visa Proof of the Country Residing -->
            <!-- <div
              class="mt-5"
              v-if="kycFormStore.formData.residentship === `Non Resident`"
            ><label :class="`${
                    $root.selectedLanguage === `ta`
                      ? `ta-label`
                      : $root.selectedLanguage === `si`
                      ? `si-label`
                      : ``
                  }`" 
                    >{{ $root.selectedLanguageData.visa_proof_of_the_country_residing}}
                    </label
                  >
              <p style="font-family: fira300; font-size: 18px">
                {{
                  $root.selectedLanguageData
                    .take_a_picture_from_your_device_or_upload_an_already_available_picture
                }}
              </p>
              <b-field
                :class="{
                  'has-name': !!kycFormStore.productImages.visa_proof_doc,
                }"
              >

                  <image-capture-component class="imageCaptureComponent" @on-captured="onVisaProofCaptured" :crop_ratio=9/13 file_name="visa_proof_doc" :selectedImageURL="kycFormStore.imageURLsvisa_proof_doc" :uploadButtonLabel="$root.selectedLanguageData.clickToUpload" :captureButtonLabel="$root.selectedLanguageData.capture"></image-capture-component>

                  
              </b-field>
            </div>-->
            <!-- Address Proof -->
            <div class="mt-6">
              <label
                :class="`${
                  $root.selectedLanguage === `ta`
                    ? `ta-label`
                    : $root.selectedLanguage === `si`
                    ? `si-label`
                    : ``
                }`"
                >{{ $root.selectedLanguageData.uploadyourproofofaddress
                }}<span class="has-text-danger">*</span></label
              >
              <p class="mb-4 note-line">
                <span class="has-text-danger has-text-weight-semibold">{{
                  $root.selectedLanguageData.note
                }}</span>
                {{$root.selectedLanguageData.notePleaseaddaphotoofanElectricityWaterorTelephonebillthatclearlyshowsyournameandresidentialaddress}}
                <br>
                {{$root.selectedLanguageData.notePleaseaddaphotoofanElectricityWaterorTelephonebillthatclearlyshowsyournameandresidentialaddress2}}
              </p>
              <div class="columns is-centered">
                <div class="column is-two-thirds">
                  <div class="has-text-centered">
                    <b-field
                      :class="{
                        'has-name': !!kycFormStore.productImages.addr_proof_doc,
                      }"
                    >
                      <ValidationProvider
                        :rules="{ required: true, size: 1024, image: true }"
                        name="image"
                        v-slot="{ errors }"
                      >
                        <!-- <div
                    :style="{
                      'border-radius': '10px',
                      border: '1px solid black',
                      padding: '10px',
                    }"
                    v-if="kycFormStore.formData.addressProofSameAsNic"
                  >
                    <a>
                      <b-image
                        :src="kycFormStore.imageURLs.addr_proof_doc"
                        responsive
                      ></b-image>
                    </a>
                  </div> -->
                        <div>
                          <image-capture-component
                          :allowPdf="true"
                          @on-captured="onAddressProofCaptured" 
                          :crop_ratio="null"
                          file_name="addr_proof_doc"
                          :selectedImageURL="AddressImageURL"
                          :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                          :captureButtonLabel="$root.selectedLanguageData.capture"/>
                        </div>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- button -->
          <transition appear
                @before-enter="$animations.beforeEnterBelow"
                @enter="$animations.enterBelow"
    >
          <div class="is-flex is-flex-direction-row-reverse mt-2 mb-4">
<!--            <b-button-->
<!--              class="is-primary is-rounded specific-continue-button-en py-5"-->
<!--              :loading="continueBtnLoading"-->
<!--              @click="updateFormData()"-->
<!--              >{{ $root.selectedLanguageData.continue }}-->
<!--            </b-button>-->

            <b-button
                class="is-primary is-rounded specific-continue-button-en py-5"
                :loading="continueBtnLoading"
                @click="updateFormData()"
                :disabled="
                kycFormStore.formData.residentship === `Out of Sri Lanka`
                  ? (!kycFormStore.productImages.nic_front_image && !kycFormStore.formData.preUploadedImages.nic_front_image) ||
                  (!kycFormStore.productImages.nic_back_image  &&  !kycFormStore.formData.preUploadedImages.nic_back_image ) ||
                   (!kycFormStore.productImages.passport_image && !kycFormStore.formData.preUploadedImages.passport_image) ||
                   (!kycFormStore.productImages.passport_image_2 &&  !kycFormStore.formData.preUploadedImages.passport_image_2)||
                    (!kycFormStore.productImages.stamp_page_image && !kycFormStore.formData.preUploadedImages.stamp_page_image) ||
                   ( !kycFormStore.productImages.selfie_image &&  !kycFormStore.formData.preUploadedImages.selfie_image ) ||
                   ( !kycFormStore.productImages.sign_image &&  !kycFormStore.formData.preUploadedImages.sign_image  )||
                   ( !kycFormStore.productImages.addr_proof_doc &&  !kycFormStore.formData.preUploadedImages.addr_proof_doc )
                  :( !kycFormStore.productImages.selfie_image &&  !kycFormStore.formData.preUploadedImages.selfie_image ) ||
                     ( !kycFormStore.productImages.sign_image &&  !kycFormStore.formData.preUploadedImages.sign_image  )||
                     (!kycFormStore.productImages.nic_front_image && !kycFormStore.formData.preUploadedImages.nic_front_image) ||
                     (!kycFormStore.productImages.nic_back_image  &&  !kycFormStore.formData.preUploadedImages.nic_back_image ) ||
                     ( !kycFormStore.productImages.addr_proof_doc &&  !kycFormStore.formData.preUploadedImages.addr_proof_doc )
              "
            >{{ $root.selectedLanguageData.continue }}
            </b-button>

<!--            <b-button-->
<!--                class="is-primary is-rounded specific-continue-button-en py-5"-->
<!--                :loading="continueBtnLoading"-->
<!--                @click="updateFormData()"-->
<!--            >{{ $root.selectedLanguageData.continue }}-->
<!--            </b-button>-->


            <b-button
              class="is-rounded specific-back-button-en py-5"
              @click="$router.push('vedioCallDetails')"
              >{{ $root.selectedLanguageData.back }}
            </b-button>
          </div></transition>
        </div>
      </form>
    </ValidationObserver>
    <!-- <GetHelp /> -->
  </div>
</template>
<script>
import logopage from "./CompanyTopLogo.vue";
import imageCaptureComponent from "@/components/supports/ImageCaptureComponent";
import selfieCaptureComponent from "@/components/supports/SelfieCaptureComponent.vue";
// import {ApiRequestManager} from "@/utils/networkManager/apiRequestManager";
import { useKycFormStore } from "@/stores/KycFormStore";
import NetworkManager from "@/utils/networkManager";
import {ApiRequestManager} from "@/utils/networkManager/apiRequestManager";
import {BuefyHelper} from "@/heplers/buefyHelper";

// import GetHelp from "./GetHelp.vue";

export default {
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },
  components: {
    logopage,
    imageCaptureComponent,
    selfieCaptureComponent
    // GetHelp,
  },
  data() {
    return {
      continueBtnLoading: false,
      pdfPlaceholder: require("@/assets/images/icons/pdf.png"),
    };
  },
  //image url create
  watch: {
    "kycFormStore.productImages.nic_front_image": function () {
      console.log("Active nic front image watcher =======================",this.kycFormStore.productImages.nic_front_image)
      if (this.kycFormStore.productImages.nic_front_image) {
        this.kycFormStore.imageURLs.nic_front_image = URL.createObjectURL(
          this.kycFormStore.productImages.nic_front_image
        );
      }
    },
    "kycFormStore.productImages.nic_back_image": function () {
      console.log("Active nic rear image watcher =======================")
      if (this.kycFormStore.productImages.nic_back_image) {
        this.kycFormStore.imageURLs.nic_back_image = URL.createObjectURL(
          this.kycFormStore.productImages.nic_back_image
        );
      }
    },
    "kycFormStore.productImages.passport_image": function () {
      console.log("Active passport image watcher =======================")
      if (this.kycFormStore.productImages.passport_image) {
        this.kycFormStore.imageURLs.passport_image = URL.createObjectURL(
          this.kycFormStore.productImages.passport_image
        );
      }
    },
    "kycFormStore.productImages.passport_image_2": function () {
      console.log("Active passport 2 image watcher =======================")
      if (this.kycFormStore.productImages.passport_image_2) {
        this.kycFormStore.imageURLs.passport_image_2 = URL.createObjectURL(
          this.kycFormStore.productImages.passport_image_2
        );
      }
    },
    "kycFormStore.productImages.stamp_page_image": function () {
      console.log("Active nic stamp page watcher =======================")
      if (this.kycFormStore.productImages.stamp_page_image) {
        this.kycFormStore.imageURLs.stamp_page_image = URL.createObjectURL(
          this.kycFormStore.productImages.stamp_page_image
        );
      }
    },
    "kycFormStore.productImages.visa_image": function () {
      console.log("Active visa image watcher =======================")
      if (this.kycFormStore.productImages.visa_image) {
        this.kycFormStore.imageURLs.visa_image = URL.createObjectURL(
          this.kycFormStore.productImages.visa_image
        );
      }
    },
    "kycFormStore.productImages.selfie_image": function () {
      console.log("Active selfie image watcher =======================")
      if (this.kycFormStore.productImages.selfie_image) {
        this.kycFormStore.imageURLs.selfie_image = URL.createObjectURL(
          this.kycFormStore.productImages.selfie_image
        );
      }
    },
    "kycFormStore.productImages.sign_image": function () {
      console.log("Active sign image watcher =======================")
      if (this.kycFormStore.productImages.sign_image) {
        this.kycFormStore.imageURLs.sign_image = URL.createObjectURL(
          this.kycFormStore.productImages.sign_image
        );
      }
    },
    "kycFormStore.productImages.visa_proof_doc": function () {
      console.log("Active visa proof image watcher =======================")
      if (this.kycFormStore.productImages.visa_proof_doc) {
        this.kycFormStore.imageURLsvisa_proof_doc = URL.createObjectURL(
          this.kycFormStore.productImages.visa_proof_doc
        );
      }
    },
    "kycFormStore.productImages.addr_proof_doc": function () {
      console.log("Active address proof image watcher =======================")
      if (this.kycFormStore.productImages.addr_proof_doc) {
        this.kycFormStore.imageURLs.addr_proof_doc = URL.createObjectURL(
          this.kycFormStore.productImages.addr_proof_doc
        );
      }
    },
    "kycFormStore.formData.addressProofSameAsNic": function () {
      console.log("Active addressProofSameAsNic image watcher =======================")
      if (this.kycFormStore.formData.addressProofSameAsNic) {
        this.kycFormStore.productImages.addr_proof_doc =
          this.kycFormStore.productImages.nic_back_image;
      } else {
        this.kycFormStore.productImages.addr_proof_doc = null;
        this.kycFormStore.imageURLs.addr_proof_doc = "";
      }
    },
  },


  computed : {
    NicFrontImageURL(){
      const comp = this
      return comp.getSelectedImageUrl("nic_front_image")
    },
    NicBackImageURL(){
      const comp = this
      return comp.getSelectedImageUrl("nic_back_image")
    },
    PassportImageURL(){
      const comp = this
      return comp.getSelectedImageUrl("passport_image")
   },
    PassportImage2URL(){
      const comp = this
      return comp.getSelectedImageUrl("passport_image_2")
   },
    StampImageURL(){
      const comp = this
      return comp.getSelectedImageUrl("stamp_page_image")
   },
    VisaImageURL(){
      const comp = this
      return comp.getSelectedImageUrl("visa_image")
     },
    SelfieImageURL(){
      const comp = this
      return comp.getSelectedImageUrl("selfie_image")
    },
    SignImageURL(){
      const comp = this
      return comp.getSelectedImageUrl("sign_image")
    },
    AddressImageURL(){
      const comp = this
      return comp.getSelectedImageUrl("addr_proof_doc")
  }
  },

  methods: {

    getSelectedImageUrl(key){
      const comp = this
      if(comp.kycFormStore.formData.preUploadedAttachmentTypes[key] !== "image" && comp.kycFormStore.formData.preUploadedAttachmentTypes[key] !==""){
        return comp.pdfPlaceholder;
      }
      else {
        if(comp.checkNewFileSelected(key)){
        console.log("getSelectedImageUrl  - "+key+" - inside if block *******" )
        return comp.kycFormStore.imageURLs[key];
      }
      else {
          console.log("getSelectedImageUrl  - "+key+" - inside else block *******" )
          return comp.kycFormStore.formData.preUploadedImages[key] ;
        }
      }
    },

    onNicCaptured: function (e) {
      // alert(e)
      this.kycFormStore.productImages.nic_front_image = e.file;
      this.kycFormStore.formData.preUploadedAttachmentTypes.nic_front_image = e.type
      console.log(
        "NIC front image : " + this.kycFormStore.productImages.nic_front_image
      );
    },
    onNicBackCaptured: function (e) {
      this.kycFormStore.productImages.nic_back_image = e.file;
      this.kycFormStore.formData.preUploadedAttachmentTypes.nic_back_image = e.type
      console.log(
        "NIC back image : " + this.kycFormStore.productImages.nic_back_image
      );
    },

    onSelfieCaptured: function (e) {


      console.log("Selfie captured - RESULT ---->",e)

      if(e === null){
        // unverified
        // handle attachment data
        console.log("Face unverified")

        this.kycFormStore.productImages.selfie_image = ""
        this.kycFormStore.formData.preUploadedAttachmentTypes.selfie_image = ""
        this.kycFormStore.selfieLivenessResult = null
        this.kycFormStore.selfieLivenessVideoBase64Url = null
      }
      else{
        // handle attachment data - verified

        console.log("Face unverified")
        this.kycFormStore.productImages.selfie_image = e.attachment.file;
        this.kycFormStore.formData.preUploadedAttachmentTypes.selfie_image = e.attachment.type
        console.log("Selfie image : " + this.kycFormStore.productImages.selfie_image)

        // handle liveness result
        this.kycFormStore.selfieLivenessResult = e.livenessResult
        //selfie liveness video 
        this.kycFormStore.selfieLivenessVideoBase64Url = e.selfieLivenessVideoBase64Url

      }

    },

  


    onSignatureCaptured: function (e) {
      this.kycFormStore.productImages.sign_image = e.file;
      this.kycFormStore.formData.preUploadedAttachmentTypes.sign_image = e.type
      console.log(
        "Signature image : " + this.kycFormStore.productImages.sign_image
      );
    },

    onAddressProofCaptured: function (e) {
      this.kycFormStore.productImages.addr_proof_doc = e.file;
      this.kycFormStore.formData.preUploadedAttachmentTypes.addr_proof_doc = e.type
      console.log(
        "Address proof image : " +
          this.kycFormStore.productImages.addr_proof_doc
      );
    },

    onPassportCaptured: function (e) {
      this.kycFormStore.productImages.passport_image = e.file;
      this.kycFormStore.formData.preUploadedAttachmentTypes.passport_image = e.type
      console.log(
        "Passport image : " + this.kycFormStore.productImages.passport_image
      );
    },

    onPassportSecoundCaptured: function (e) {
      this.kycFormStore.productImages.passport_image_2 = e.file;
      this.kycFormStore.formData.preUploadedAttachmentTypes.passport_image_2 = e.type
      console.log(
        "Passport image : " + this.kycFormStore.productImages.passport_image_2
      );
    },
    onStampPageCaptured: function (e) {
      this.kycFormStore.productImages.stamp_page_image = e.file;
      this.kycFormStore.formData.preUploadedAttachmentTypes.stamp_page_image = e.type
      console.log(
        "Passport image : " + this.kycFormStore.productImages.stamp_page_image
      );
    },
    onVisaCaptured: function (e) {
      this.kycFormStore.productImages.visa_image = e.file;
      this.kycFormStore.formData.preUploadedAttachmentTypes.visa_image = e.type
      console.log("Visa image : " + this.kycFormStore.productImages.visa_image);
    },
    onVisaProofCaptured: function (e) {
      this.kycFormStore.productImages.visa_proof_doc = e.file;
      this.kycFormStore.formData.preUploadedAttachmentTypes.visa_proof_doc = e.type
      console.log(
        "Visa proof image : " + this.kycFormStore.productImages.visa_proof_doc
      );
    },
    updateFormData: function () {
      const comp = this;
      comp.continueBtnLoading = true;
      //face validation API start
      comp.validateFaceInImage("nic_front_image", comp.getImageValueToSubmit("nic_front_image"),()=>{
        comp.validateFaceInImage("selfie_image", comp.getImageValueToSubmit("selfie_image"),()=>{
          comp.checkFaceValidated();
        })
      });
      // .then(() => {
      //   return comp.validateFaceInImage("selfie_image", comp.getImageValueToSubmit("selfie_image"));
      // })
      // .then(() => {
      //   return comp.checkFaceValidated();
      // })
      // .catch(error => {
      //   // Handle errors if needed
      //   console.error(error);
      // });
     },



  
     validateFaceInImage: function (type,image,callback){
      const comp = this
      let base64_data = null

      if(image != null){
        var fileReader = new FileReader();
            var base64;
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                 base64_data = base64.split(";base64,")[1]
                // Print data in console
                if(base64_data != null){
                  
                    const validateImage = {
                      'image': base64_data,
                    }
                    NetworkManager.apiRequest("api/Cognitive/aws/detectFace",  validateImage, function (response) {
                          if (response.statusCode === 200) {
                            if(response.data.face_detection_result.face_detected){
                              if(type === 'nic_front_image'){
                                if(response.data.face_detection_result.result.mask_detected){
                                      BuefyHelper.showToastMessage(
                                        "Please remove the face mask from NIC front side image!",
                                        "is-danger"
                                      )
                                  comp.continueBtnLoading = false;
                                  comp.kycFormStore.formData.nic_face_detection_details = null;
                                }
                                else{
                                  comp.kycFormStore.formData.nic_face_detection_details = response.data.face_detection_result
                                }
                              }
                              else if(type === 'selfie_image'){
                                if(response.data.face_detection_result.result.mask_detected){
                                      BuefyHelper.showToastMessage(
                                        "Please remove the face mask from selfie image!",
                                        "is-danger"
                                      )
                                  comp.continueBtnLoading = false;
                                  comp.kycFormStore.formData.selfie_face_detection_details = null;
                                }
                                else{
                                  comp.kycFormStore.formData.selfie_face_detection_details = response.data.face_detection_result
                                }
                              }
                                  
                            }else{
                              if(type == 'nic_front_image'){
                                BuefyHelper.showToastMessage(
                                  "No face detected in NIC front side image!",
                                  "is-danger"
                                )
                              }else if(type == 'selfie_image'){
                                BuefyHelper.showToastMessage(
                                  "No face detected in selfie image!",
                                  "is-danger"
                                )
                              }
                              comp.continueBtnLoading = false;

                            }

                            if(typeof callback === "function"){
                              callback()
                            }
                          }
                          else {
                            if(typeof callback === "function"){
                              callback()
                            }
                            // Unable to upload the documents
                          }
                    } );

                    }
            };
            // Convert data to base64
            fileReader.readAsDataURL(image);
      }else{
        if(typeof callback === "function"){
                              callback()
                            }
      }
     
     },

     checkFaceValidated: function(){
      const comp = this
     console.log("in checkFaceValidated")
     console.log(comp.kycFormStore.formData.nic_face_detection_details)
      if(comp.kycFormStore.formData.nic_face_detection_details == null || comp.kycFormStore.formData.selfie_face_detection_details == null){
        console.log("in setTimeOut")
        setTimeout(this.checkFaceIdsReceived,100)
      }
      else{
        // TODO :: handle face not detected validation
        if(comp.kycFormStore.formData.nic_face_detection_details != null && comp.kycFormStore.formData.selfie_face_detection_details != null){ // enable to continue if face is detected in nic front and selfie images
           if(comp.kycFormStore.formData.gender.toLowerCase() != comp.kycFormStore.formData.nic_face_detection_details.result.gender.toLowerCase()){
            BuefyHelper.showToastMessage(
                                  "NIC Image gender didn't match with your data!",
                                  "is-danger"
                                )
            comp.continueBtnLoading = false;

           }else if(comp.kycFormStore.formData.gender.toLowerCase() != comp.kycFormStore.formData.selfie_face_detection_details.result.gender.toLowerCase()){
            BuefyHelper.showToastMessage(
                                  "Selfie Image gender didn't match with your data!",
                                  "is-danger"
                                )
            comp.continueBtnLoading = false;

           }else if((comp.kycFormStore.formData.gender.toLowerCase() == comp.kycFormStore.formData.nic_face_detection_details.result.gender.toLowerCase()) &&  (comp.kycFormStore.formData.gender.toLowerCase() == comp.kycFormStore.formData.selfie_face_detection_details.result.gender.toLowerCase())){
            console.log("all faces validated")
            comp.uploadDocuments();

           }
        }
       
      }

     },
    checkAnyPreUploadImageAvailable(){
      const comp = this

      return comp.kycFormStore.formData.preUploadedImages.nic_front_image !== "" ||
          comp.kycFormStore.formData.preUploadedImages.nic_back_image !== "" ||
          comp.kycFormStore.formData.preUploadedImages.passport_image !== "" ||
          comp.kycFormStore.formData.preUploadedImages.passport_image_2 !== "" ||
          comp.kycFormStore.formData.preUploadedImages.stamp_page_image !== "" ||
          comp.kycFormStore.formData.preUploadedImages.visa_image !== "" ||
          comp.kycFormStore.formData.preUploadedImages.selfie_image !== "" ||
          comp.kycFormStore.formData.preUploadedImages.sign_image !== "" ||
          comp.kycFormStore.formData.preUploadedImages.addr_proof_doc !== ""
    },

    checkNewFileSelected(key){
      const comp = this
      return comp.kycFormStore.previousProductImages[key] !== comp.kycFormStore.productImages[key]
    },

    getImageValueToSubmit(key){
      const comp = this

      return comp.checkNewFileSelected(key) ?  comp.kycFormStore.productImages[key] : null;
    },


    uploadDocuments: function () {

      const comp = this;

      // eslint-disable-next-line no-empty
      const documentUploadRequest = new FormData();
      documentUploadRequest.append("kyc_landing_token", comp.kycFormStore.formData.landing_token)
      documentUploadRequest.append("nic_front_image", comp.getImageValueToSubmit("nic_front_image"));
      documentUploadRequest.append("nic_back_image", comp.getImageValueToSubmit("nic_back_image"));
      documentUploadRequest.append("passport_bio_page_image",comp.getImageValueToSubmit("passport_image"));
      documentUploadRequest.append("passport_alteration_page_image",comp.getImageValueToSubmit("passport_image_2"));
      documentUploadRequest.append("passport_entry_stamp_page_image", comp.getImageValueToSubmit("stamp_page_image"));
      documentUploadRequest.append("visa_image", comp.getImageValueToSubmit("visa_image"));
      documentUploadRequest.append("selfie_image", comp.getImageValueToSubmit("selfie_image"));
      documentUploadRequest.append("signature_image", comp.getImageValueToSubmit("sign_image"));
      documentUploadRequest.append("address_proof_image", comp.getImageValueToSubmit("addr_proof_doc"));

      console.log("Document upload request list =================",documentUploadRequest);

      NetworkManager.apiRequest("api/KycForm/preUploadFormDocuments",  documentUploadRequest, function (response) {
            if (response.statusCode === 200) {
              // update urls for the uploaded files
              if (comp.checkNewFileSelected("nic_front_image")) {
                comp.kycFormStore.formData.preUploadedImages.nic_front_image = response.data.kyc_document_upload_result.nic_front_image;
                comp.kycFormStore.previousProductImages.nic_front_image = comp.kycFormStore.productImages.nic_front_image;
              }

              if (comp.checkNewFileSelected("nic_back_image")) {
                comp.kycFormStore.formData.preUploadedImages.nic_back_image = response.data.kyc_document_upload_result.nic_back_image;
                comp.kycFormStore.previousProductImages.nic_back_image = comp.kycFormStore.productImages.nic_back_image;
              }

              if(comp.checkNewFileSelected("passport_image")){
                comp.kycFormStore.formData.preUploadedImages.passport_image = response.data.kyc_document_upload_result.passport_bio_page_image;
                comp.kycFormStore.previousProductImages.passport_image = comp.kycFormStore.productImages.passport_image;
              }

              if (comp.checkNewFileSelected("passport_image_2")) {
                comp.kycFormStore.formData.preUploadedImages.passport_image_2 = response.data.kyc_document_upload_result.passport_alteration_page_image;
                comp.kycFormStore.previousProductImages.passport_image_2 = comp.kycFormStore.productImages.passport_image_2;
              }

              if (comp.checkNewFileSelected("stamp_page_image")) {
                comp.kycFormStore.formData.preUploadedImages.stamp_page_image = response.data.kyc_document_upload_result.passport_entry_stamp_page_image;
                comp.kycFormStore.previousProductImages.stamp_page_image = comp.kycFormStore.productImages.stamp_page_image;
              }

              if (comp.checkNewFileSelected("visa_image")) {
                comp.kycFormStore.formData.preUploadedImages.visa_image = response.data.kyc_document_upload_result.visa_image;
                comp.kycFormStore.previousProductImages.visa_image = comp.kycFormStore.productImages.visa_image;
              }

              if (comp.checkNewFileSelected("selfie_image")) {
                comp.kycFormStore.formData.preUploadedImages.selfie_image = response.data.kyc_document_upload_result.selfie_image;
                comp.kycFormStore.previousProductImages.selfie_image = comp.kycFormStore.productImages.selfie_image;
              }

              if (comp.checkNewFileSelected("sign_image")) {
                comp.kycFormStore.formData.preUploadedImages.sign_image = response.data.kyc_document_upload_result.signature_image;
                comp.kycFormStore.previousProductImages.sign_image = comp.kycFormStore.productImages.sign_image;
              }

              if (comp.checkNewFileSelected("addr_proof_doc")) {
                comp.kycFormStore.formData.preUploadedImages.addr_proof_doc = response.data.kyc_document_upload_result.address_proof_image;
                comp.kycFormStore.previousProductImages.addr_proof_doc = comp.kycFormStore.productImages.addr_proof_doc;
              }


              // update last step
              ApiRequestManager.updateLastStep(  comp.kycFormStore.formData.landing_token,  "6",   "/previewPage", comp.kycFormStore.formData, function (status) {
                    if (status) {
                      comp.$router.push("/previewPage");
                      comp.continueBtnLoading = false;
                    }
                    else {
                      comp.continueBtnLoading = false;
                    }
                  }
              );


            }
            else {
              // Unable to upload the documents
            }
      } );
    },


    handleBrowserRefresh(e) {
      console.log("call image in mounted ================================================")
      this.kycFormStore.productImages.nic_front_image = e;
    },

    getPageRefresh(event) {
      const comp = this
      //event.preventDefault();
      console.log("Lost data refresh ===============================================")
      event.returnValue = "Data will be lost if you leave the page, are you sure?";
      if(event.returnValue){
        //clear images from store
        comp.kycFormStore.productImages.nic_front_image = null;
        comp.kycFormStore.productImages.nic_back_image = null;
        comp.kycFormStore.productImages.passport_image = null;
        comp.kycFormStore.productImages.passport_image_2 = null;
        comp.kycFormStore.productImages.stamp_page_image = null;
        comp.kycFormStore.productImages.selfie_image = null;
        comp.kycFormStore.productImages.sign_image = null;
        comp.kycFormStore.productImages.addr_proof_doc = null;
        comp.kycFormStore.productImages.visa_image = null

        //clear image url
        comp.kycFormStore.imageURLs.nic_front_image = null;
        comp.kycFormStore.imageURLs.nic_back_image = null;
        comp.kycFormStore.imageURLs.selfie_image = null;
        comp.kycFormStore.imageURLs.sign_image = null;
        comp.kycFormStore.imageURLs.addr_proof_doc = null;
        comp.kycFormStore.imageURLs.passport_image = null;
        comp.kycFormStore.imageURLs.passport_image_2 = null;
        comp.kycFormStore.imageURLsvisa_proof_doc = null;
        comp.kycFormStore.imageURLs.stamp_page_image = null;
        comp.kycFormStore.imageURLs.visa_image = null;
      }

    },
  },
  mounted() {
    //const comp = this;
    //check windows refresh event
    //window.addEventListener('beforeunload', comp.getPageRefresh);

  },
  beforeDestroy() {
    //const comp = this
    //window.removeEventListener('beforeunload', comp.getPageRefresh);
  },
};
</script>
<style>
.image {
  display: flex;
  position: relative;
  justify-content: center;
}

.imageCaptureComponent {
  width: 60%;
  margin: auto;
}
</style>
