<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
      <logopage
    /></transition>
    <div class="columns is-centered mb-0">
      <transition
        appear
        @before-enter="$animations.beforeEnterFade"
        @enter="$animations.enterFade"
      >
        <div
          v-if="$root.selectedLanguage === `en`"
          class="column"
          style="text-align: center"
        >
          <img
            src="../assets/images/header-img/VerificationPage.png"
            alt="VerificationPage-img"
            class="page-header-img"
          /></div
      ></transition>
    </div>
    <transition
      appear
      @before-enter="$animations.beforeEnterFade"
      @enter="$animations.enterFade"
    >
      <div class="columns is-centered mb-0">
        <div class="column is-half pt-2 ps-5" style="text-align: center">
          <p class="subhead-line is-size-4" style="line-height: 20px">
            <img
              src="../assets/images/icons/Shield-Done.png"
              alt="Shield-Done"
              width="15%"
            />
          </p>
          <p class="headline-font" style="line-height: 42px">
            {{ $root.selectedLanguageData.verification }}
          </p>

          <p class="specific-line">
            {{ $root.selectedLanguageData.hi }}
            <span
              :style="{
                'text-justify': 'inter-word',
                'word-break': 'break-all',
                'line-height': '20px',
              }"
              >{{ kycFormStore.formData.calling_name || "Charith" }},</span
            >
            {{ $root.selectedLanguageData.we_sent_an_otp }}
          </p>
          <p style="font-family: Gotham-Bold" class="is-size-6">
            {{ kycFormStore.formData.email || "xxxxxx@gmail.com" }}
          </p>
        </div>
      </div></transition
    >
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <div class="form-body mt-3">
          <transition
            appear
            @before-enter="$animations.beforeEnterBelow"
            @enter="$animations.enterBelow"
          >
            <div class="box box-div px-5">
              <p class="has-text-centered mb-4 astric-font-size">
                <span class="has-text-danger mr-2">{{
                  $root.selectedLanguageData.note
                }}</span
                ><b class="has-text-danger">*</b
                ><span class="text-transparency">
                  Indicates mandatory fields that are required</span
                >
              </p>
              <div class="mb-1 otp-font-size">
                <b-field
                  label-position="inside"
                  :custom-class="
                    email_otp.trim() === '' && !isOtpInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{ $root.selectedLanguageData.please_enter_otp
                    }}<span class="has-text-danger">*</span></template
                  >

                  <p class="control has-icons-right">
                    <ValidationProvider
                      name="OTP"
                      :rules="{ length: 4, required: true, numeric: true }"
                      v-slot="{ errors }"
                    >
                      <b-input
                        rounded
                        :maxlength="kycFormStore.formDataSupport.otpLength"
                        v-model="email_otp"
                        @focus="
                          () => {
                            isOtpInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isOtpInputFocus = false;
                          }
                        "
                      />
                      <span class="icon is-small is-right mr-1">
                        <img
                          :src=otpImage
                          alt="otp-img"
                          width="30"
                          height="30"
                        />
                      </span>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </p>
                </b-field>
              </div>

              <div class="columns is-mobile mb-2">
                <div class="column">
                  <div class="flex-start align">
                    <b-field>
                      <b-radio
                        :native-value="true"
                        v-model="kycFormStore.formData.email_otp_not_receive"
                      ></b-radio>
                      <p style="text-align: start">
                        {{ $root.selectedLanguageData.didnot_receive }}
                      </p></b-field
                    >
                  </div>
                </div>
                <div class="column">
                  <p style="text-align: end">
                    {{ $root.selectedLanguageData.resend_otp_in_verification }}
                    <span style="color: #ed1c24; font-family: Gotham-Medium">
                      {{ countdownTime }}s</span
                    >
                  </p>
                </div>
              </div>

              <!-- click here -->
              <div v-if="kycFormStore.formData.email_otp_not_receive">
                <transition
                  appear
                  @before-enter="$animations.beforeEnterAbove"
                  @enter="$animations.enterAbove"
                >
                  <p
                    style="line-height: 35px"
                    @click="
                      () => {
                        isModalActive = true;
                        isEmailChanges = false;
                        yourNewMail = kycFormStore.formData.email;
                        // if (!countdownTime) {
                        //   resendOTP();
                        // }else{
                        //   countdownTime=60
                        // }
                      }
                    "
                  >
                    <span style="color: #ed1c24" class="link_style">{{
                      $root.selectedLanguageData.click_here
                    }}</span
                    >{{ $root.selectedLanguageData.email_difference_detail }}
                  </p></transition
                >
                <transition
                  appear
                  @before-enter="$animations.beforeEnterAbove"
                  @enter="$animations.enterAbove"
                >
                  <p
                    :class="countdownTime ? `otp_text` : ``"
                    @click="
                      () => {
                        if (!countdownTime) {
                          resendOTP();
                        }
                      }
                    "
                  >
                    <span style="color: #ed1c24" class="link_style mt-1">{{
                      $root.selectedLanguageData.click_here
                    }}</span
                    >{{ $root.selectedLanguageData.resend_otp }}
                  </p></transition
                >
              </div>
            </div>
          </transition>
          <transition
            appear
            @before-enter="$animations.beforeEnterBelow"
            @enter="$animations.enterBelow"
          >
            <div class="is-flex is-flex-direction-row-reverse mt-2 mb-4">
              <b-button
                class="is-primary is-rounded continue-button-en py-5"
                :disabled="invalid || noImages"
                :loading="isSubmitLoading"
                @click="validateOtp"
                >{{ $root.selectedLanguageData.continue }}
              </b-button>
              <b-button
                :disabled="isdisabled"
                class="is-rounded back-button-en py-5"
                @click="$router.push('previewPage')"
                >{{ $root.selectedLanguageData.back }}
              </b-button>
            </div></transition
          >
        </div>
      </form>
    </ValidationObserver>
    <ValidationObserver v-slot="{ invalid }">
      <!-- new -->
      <b-modal v-model="isModalActive" scroll="keep" :width="540">
        <transition
          appear
          @before-enter="$animations.beforeEnterAbove"
          @enter="$animations.enterAbove"
        >
          <div class="model_box mt-3 px-3 mx-2">
            <div style="transition-timing-function: ease-in-out">
              <div class="modal-close-button is-pulled-right mb-6">
                <button
                  class="delete"
                  aria-label="close"
                  @click="isModalActive = false"
                ></button>
              </div>
              <div>
                <img
                  :src="
                    require(`../assets/images/icons/verificationEmail.gif`)
                  "
                  responsive
                  class="has-text-centered account_fail"
                />

                <p
                  class="hero-subhead-line has-text-primary is-uppercase has-text-centered"
                >
                  {{ $root.selectedLanguageData.changeemail }}
                </p>
                <div class="mb-4 has-text-centered" style="font-size: 20px">
                  {{ $root.selectedLanguageData.mobileNumChangeConditon }}
                </div>
                <div class="has-text-left">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      yourNewMail.trim() === '' && !isEmailIdInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.new_email_address
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{
                          email: true,
                          required: true,
                          lessOrEqualLength: 256,
                        }"
                        name="Email ID"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          v-model="yourNewMail"
                          @focus="
                            () => {
                              isEmailIdInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmailIdInputFocus = false;
                            }
                          "
                          @input="
                            () => {
                              if(yourNewMail == kycFormStore.formData.email)
                              {
                                isEmailChanges = false;
                              }
                              else
                              {
                                isEmailChanges = true;
                                verifyYourNewMail();
                              }
                            }
                          "
                        ></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/Message.png"
                          alt="Call-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>

                <b-button
                  type="is-primary"
                  class="py-5 mt-5 has-text-centered"
                  rounded
                  expanded
                  :disabled="invalid || !isEmailChanges"
                  @click="
                    () => {
                      isModalActive = false;
                      kycFormStore.formData.email = yourNewMail;
                      showSuccessMessage();
                    }
                  "
                  >{{ $root.selectedLanguageData.confirm }}
                </b-button>
              </div>
            </div>
          </div></transition
        >
      </b-modal>
    </ValidationObserver>
    <!-- <GetHelp /> -->
  </div>
</template>
<script>
import logopage from "./CompanyTopLogo.vue";
import NetworkManager from "@/utils/networkManager";
import { useKycFormStore } from "@/stores/KycFormStore";
//import {PiniaStoreHelper} from "@/heplers/piniaStoreHelper";
import { PepDeclaration } from "@/heplers/classes/pepDeclaration/PepDeclaration";
import { PepPersonDetail } from "@/heplers/classes/pepDeclaration/PepPersonDetail";
import { PiniaStoreHelper } from "@/heplers/piniaStoreHelper";

import { ValidationObserver } from "vee-validate";
import { BuefyHelper } from "@/heplers/buefyHelper";
// import GetHelp from "./GetHelp.vue";
import { ApiRequestManager } from "@/utils/networkManager/apiRequestManager";
import {SubmitAttachment} from "@/heplers/classes/attachment/submitAttachment";
export default {
  /*
   * ===================================================================================================================
   * expose kyc form pinia store and pini storage helper to the component
   */

  setup() {
    const kycFormStore = useKycFormStore();
    const piniaStorageHelper = PiniaStoreHelper;
    return { kycFormStore, piniaStorageHelper };
  },
  components: {
    logopage,
    ValidationObserver,
    // GetHelp,
  },
  data() {
    return {
      otpImage : require("@/assets/images/icons/Otp.png"),
      noImages: false,
      isdisabled: false,
      isSubmitLoading: false,
      kyc_form_id: null,
      pepDeclaration: null,
      productDetail1: null,
      productDetail2: null,
      productDetail3: null,
      productDetails: [],
      isModalActive: false,
      email_otp: "",
      isOtpInputFocus: false,
      isEmailIdInputFocus: false,
      yourNewMail: "",
      countdownTime: "",
      formSubmitting: false,
      isEmailChanges: false,
      referenceNumber: "",
      working_platforms: [],
    };
  },
  beforeDestroy() {
    //window.removeEventListener("beforeunload", this.getPageRefresh);
  },

  mounted() {
    //this.checkImageData();
    //window.addEventListener("beforeunload", this.getPageRefresh);
    // send otp
    ApiRequestManager.sendOtp(
      false,
      true,
      this.kycFormStore.formData.customer_nic,
      "",
      this.kycFormStore.formData.email
    );

    this.kycFormStore.formData.email_otp_not_receive = false;
    this.addPepDeclaration();
    this.addWorkingPlaces();
    this.yourNewMail = this.kycFormStore.formData.email;
    this.countdownTime = 60;
    this.countdown();
  },

  methods: {
    checkImageData: function () {
      console.log("checking images........");
      if (
        this.kycFormStore.productImages.nic_front_image == null ||
        this.kycFormStore.productImages.nic_back_image == null ||
        this.kycFormStore.productImages.sign_image == null ||
        this.kycFormStore.productImages.addr_proof_doc == null ||
        this.kycFormStore.productImages.selfie_image == null ||
        ((this.kycFormStore.productImages.passport_image ||
          this.kycFormStore.productImages.passport_image_2 ||
          this.kycFormStore.productImages.stamp_page_image ||
          this.kycFormStore.productImages.visa_image) &&
          (this.kycFormStore.productImages.passport_image == null ||
            this.kycFormStore.productImages.passport_image_2 == null ||
            this.kycFormStore.productImages.stamp_page_image == null ||
            this.kycFormStore.productImages.visa_image == null))
      ) {
        this.noImages = true;
        this.$buefy.snackbar.open({
          indefinite: true,
          position: "is-top",
          message: "Some of the data was lost. Please re-check them again.",
          type: "is-danger",
          actionText: "OK",
          queue: false,
        });
      }
    },
    verifyYourNewMail() {
      this.yourNewMail = this.yourNewMail.toLocaleLowerCase();
    },
    showSuccessMessage() {
      BuefyHelper.showToastMessage(
        "Primary Email ID changed successfully",
        "is-danger "
      );
      if (!this.countdownTime) {
        this.countdownTime = 60;
        this.resendOTP();
      } else {
        this.countdownTime = 60;
        this.resendOTP()
      }
    },
    countdown() {
      if (this.countdownTime > 0) {
        setTimeout(() => {
          this.countdownTime -= 1;
          this.countdown();
        }, 1000);
      }
    },
    resendOTP() {
      console.log("OTP sent");
      this.countdownTime = 0;

      // send otp
      ApiRequestManager.sendOtp(
        false,
        true,
        this.kycFormStore.formData.customer_nic,
        "",
        this.kycFormStore.formData.email
      );

      this.countdownTime = 60;
      this.countdown();
    },
    addPepDeclaration: function () {
      const comp = this;
      if (
        comp.kycFormStore.formData.politically_exposed ==
        "im_politically_exposed"
      ) {
        comp.pepDeclaration = new PepDeclaration(true, false, false);
        if (
          comp.kycFormStore.formData.politically_exposed_name != "" ||
          comp.kycFormStore.formData.politically_exposed_position != ""
        ) {
          comp.pepDeclaration.addPepPersonDetail(
            new PepPersonDetail(
              comp.kycFormStore.formData.politically_exposed_name,
              comp.kycFormStore.formData.politically_exposed_position
            )
          );
        }
        if (
          comp.kycFormStore.formData.second_politically_exposed_name != "" ||
          comp.kycFormStore.formData.second_politically_exposed_position != ""
        ) {
          comp.pepDeclaration.addPepPersonDetail(
            new PepPersonDetail(
              comp.kycFormStore.formData.second_politically_exposed_name,
              comp.kycFormStore.formData.second_politically_exposed_position
            )
          );
        }
      } else if (
        comp.kycFormStore.formData.politically_exposed ==
        "my_close_relative_politically_exposed"
      ) {
        comp.pepDeclaration = new PepDeclaration(false, true, false);
        if (
          comp.kycFormStore.formData.politically_exposed_relative != "" ||
          comp.kycFormStore.formData.politically_exposed_relative_position != ""
        ) {
          comp.pepDeclaration.addPepPersonDetail(
            new PepPersonDetail(
              comp.kycFormStore.formData.politically_exposed_relative,
              comp.kycFormStore.formData.politically_exposed_relative_position
            )
          );
        }
        if (comp.kycFormStore.formData.second_politically_exposed_relative != "" || comp.kycFormStore.formData.second_politically_exposed_relative_position != "" ) {
          comp.pepDeclaration.addPepPersonDetail(
            new PepPersonDetail(
              comp.kycFormStore.formData.second_politically_exposed_relative,
              comp.kycFormStore.formData.second_politically_exposed_relative_position
            )
          );
        }
      } else {
        comp.pepDeclaration = new PepDeclaration(false, false, true);
      }
    },
    addWorkingPlaces() {
      const comp = this;
      comp.kycFormStore.formData.working_platforms.forEach((element) => {
        comp.working_platforms.push(element.value);
      });
    },

    /**
     * =================================================================================================================
     * This function can be used to validate the otp
     */
    validateOtp: function () {
      const comp = this;
      comp.isdisabled = true;
      ApiRequestManager.validateOtp(
        comp.email_otp,
        comp.kycFormStore.formData.customer_nic,
        (otpVerified) => {
          if (otpVerified) {
            // otp is verified
            comp.submitKyc();
          } else {
            // otp is not verified
            this.isdisabled = false;
            BuefyHelper.showToastMessage(
              "Entered OTP does not match the OTP sent from the Email",
              "is-danger"
            );
          }
        }
      );
    },

    submitKyc: function () {
      const comp = this;
      comp.formSubmitting = true;

      comp.isSubmitLoading = true;

      const data = {        
        landing_token: comp.kycFormStore.formData.landing_token,
        calling_name: comp.kycFormStore.formData.calling_name,
        citizenship: comp.kycFormStore.formData.citizenship,
        residentship: comp.kycFormStore.formData.residentship,
        mobile_number: comp.kycFormStore.formData.contact_no,
        telephone_number: comp.kycFormStore.formData.telephone_no,
        email: comp.kycFormStore.formData.email,
        allow_location_access:
          comp.kycFormStore.formData
            .nicNumberAllowToLocationServicesInYourBrowser,
        allow_nic_verification_drp:
          comp.kycFormStore.formData.nicNumberAllowToDRP,
        identification_type: comp.kycFormStore.formData.idType,
        nic_eic_number: comp.kycFormStore.formData.customer_nic,
        passport_number: comp.kycFormStore.formData.customer_passport_no,
        nic_eic_issue_date:
          comp.kycFormStore.formData.nic_issue_date.toISOString(),

        // pass dummy date if not select any passport issued date
        passport_issue_date:
          comp.kycFormStore.formData.passport_issue_date !== null
            ? comp.kycFormStore.formData.passport_issue_date.toISOString()
            : new Date().toISOString(),
        passport_expire_date:
          comp.kycFormStore.formData.passport_expiry_date !== null
            ? comp.kycFormStore.formData.passport_expiry_date.toISOString()
            : new Date().toISOString(),
        title: comp.kycFormStore.formData.title,
        full_name: comp.kycFormStore.formData.full_name,
        nationality: comp.kycFormStore.formData.citizenship_1,
        date_of_birth: comp.kycFormStore.formData.date_of_birth.toISOString(),
        gender: comp.kycFormStore.formData.gender,
        maritial_status: comp.kycFormStore.formData.marital_status,
        is_correspondence_address_differs_with_the_permanent_address:
          comp.kycFormStore.formData
            .correspondence_address_differ_with_permanent_address,
        building_number: comp.kycFormStore.formData.permanent_address_line1,
        building_name: comp.kycFormStore.formData.permanent_address_line2,
        street: comp.kycFormStore.formData.permanent_address_line3,
        street_2 : comp.kycFormStore.formData.street_2,
        town: comp.kycFormStore.formData.permanent_address_town,
        postal_code: comp.kycFormStore.formData.permanent_address_postal_code,
        district: comp.kycFormStore.formData.permanent_address_district,
        country: comp.kycFormStore.formData.permanent_address_country,
        correspondence_address_building_number:
          comp.kycFormStore.formData.correspondent_address_line1,
        correspondence_address_building_name:
          comp.kycFormStore.formData.correspondent_address_line2,
        correspondence_address_street:
          comp.kycFormStore.formData.correspondent_address_line3,
          correspondence_address_street_2 : comp.kycFormStore.formData.correspondence_address_street_2,  
        correspondence_address_town:
          comp.kycFormStore.formData.correspondent_address_town,
        correspondence_address_postal_code:
          comp.kycFormStore.formData.correspondent_address_postal_code,
        correspondence_address_district:
          comp.kycFormStore.formData.correspondent_address_district,
        correspondence_address_country:
          comp.kycFormStore.formData.correspondent_address_country,

        state_of_residence: comp.kycFormStore.formData.status_of_residence,
        mothers_maiden_name: comp.kycFormStore.formData.maiden_name,
        employment_status: comp.kycFormStore.formData.employment_status.value,
        profession_nature_of_business:
          comp.kycFormStore.formData.nature_of_business,

        are_you_work_on_another_platform:
          comp.kycFormStore.formData.are_you_a_freelancer === null
            ? false
            : comp.kycFormStore.formData.are_you_a_freelancer,

        other_platforms_working_on: comp.working_platforms,
        other_platforms_working_on_other_selected:
          comp.kycFormStore.formData.specified_working_platforms != "",
        other_platforms_working_on_other_description:
          comp.kycFormStore.formData.specified_working_platforms,

        employer: comp.kycFormStore.formData.employer_name,
        emp_building_number: comp.kycFormStore.formData.employer_address_line1,
        emp_building_name: comp.kycFormStore.formData.employer_address_line2,
        emp_street: comp.kycFormStore.formData.employer_address_line3,
        emp_town: comp.kycFormStore.formData.employer_address_town,
        emp_postal_code:
          comp.kycFormStore.formData.employer_address_postal_code,
        emp_district: comp.kycFormStore.formData.employer_address_district,
        emp_country: comp.kycFormStore.formData.employer_address_country,

        are_you_a_tax_payer: comp.kycFormStore.formData.are_you_a_taxpayer,

        tax_file_numer: comp.kycFormStore.formData.tax_file_num,

        pep_declaration: comp.pepDeclaration,

        source_of_funds: comp.kycFormStore.formData.source_of_income,
        source_of_funds_other:
          comp.kycFormStore.formData.source_of_income.includes("Other"),
        source_of_funds_other_description:
          comp.kycFormStore.formData.specified_source_of_income,

        branch_id: comp.kycFormStore.formData.branch,

        branch_comment:
          comp.kycFormStore.formData.reason_for_opening_an_account,
        product_count: comp.kycFormStore.formData.product_selection.length,
        vas_sms_alert: comp.kycFormStore.formData.sms_service,
        vas_online_banking: comp.kycFormStore.formData.e_banking_service,
        vas_virtual_wallet: comp.kycFormStore.formData.virtual_wallet_service,
        vas_estatement: comp.kycFormStore.formData.estatement_service,
        vas_debit_card: comp.kycFormStore.formData.debit_card_service,
        vas_debit_card_collection_details:
          comp.kycFormStore.formData.collection_details,
        vas_debit_card_pin_collection_details:
          comp.kycFormStore.formData.pin_collection_details,
        vas_debit_card_name:
          comp.kycFormStore.formData.facility_debit_card_customer_name,

        vas_debit_card_pos_required: comp.kycFormStore.formData.pos_required,

        vas_debit_card_pos_account:
          comp.kycFormStore.formData.account_to_be_linked_for_pos_transaction,
        video_call_date:
          comp.kycFormStore.formData.preferred_date_for_video_call,
        video_call_time:
          comp.kycFormStore.formData.preferred_time_for_video_call,
        product_details: comp.kycFormStore.formData.product_selection,
        application_type: "Saving",
        customer_location: JSON.stringify(
          comp.kycFormStore.formData.customer_location
        ),
        age: comp.kycFormStore.formData.age,

        // mobile and telephone number in raw format
        mobile_number_country_code: comp.kycFormStore.formData.countryCode,
        mobile_number_without_country_code:
          comp.kycFormStore.formData.mobileNumber,
        telephone_number_country_code:
          comp.kycFormStore.formData.office_country_code,
        telephone_number_without_country_code:
          comp.kycFormStore.formData.officeNumber,
        secondary_email: comp.kycFormStore.formData.secondary_email,
        
        selfie_attachment: new SubmitAttachment(comp.kycFormStore.formData.preUploadedImages.selfie_image,comp.kycFormStore.formData.preUploadedAttachmentTypes.selfie_image),

        nic_front_attachment: new SubmitAttachment(comp.kycFormStore.formData.preUploadedImages.nic_front_image,comp.kycFormStore.formData.preUploadedAttachmentTypes.nic_front_image),

        nic_back_attachment : new SubmitAttachment(comp.kycFormStore.formData.preUploadedImages.nic_back_image,comp.kycFormStore.formData.preUploadedAttachmentTypes.nic_back_image),

        passport_bio_page_attachment : new SubmitAttachment(comp.kycFormStore.formData.preUploadedImages.passport_image,comp.kycFormStore.formData.preUploadedAttachmentTypes.passport_image),

        passport_alteration_attachment : new SubmitAttachment(comp.kycFormStore.formData.preUploadedImages.passport_image_2,comp.kycFormStore.formData.preUploadedAttachmentTypes.passport_image_2),

        passport_entry_stamp_attachment : new SubmitAttachment(comp.kycFormStore.formData.preUploadedImages.stamp_page_image,comp.kycFormStore.formData.preUploadedAttachmentTypes.stamp_page_image),

        visa_attachment : new SubmitAttachment(comp.kycFormStore.formData.preUploadedImages.visa_image,comp.kycFormStore.formData.preUploadedAttachmentTypes.visa_image),

        address_proof_attachment : new SubmitAttachment(comp.kycFormStore.formData.preUploadedImages.addr_proof_doc,comp.kycFormStore.formData.preUploadedAttachmentTypes.addr_proof_doc),

        signature_image : comp.kycFormStore.formData.preUploadedImages.sign_image,
        nic_face_detection_details : comp.kycFormStore.formData.nic_face_detection_details,
        selfie_face_detection_details : comp.kycFormStore.formData.selfie_face_detection_details,
        selfie_liveness : comp.kycFormStore.selfieLivenessResult,
        face_liveness_video_recording : comp.kycFormStore.selfieLivenessVideoBase64Url
      };
      //const data=comp.$root.formData
      console.log("UPLOADING THE PAYLOAD", data);
      NetworkManager.apiRequest(
        "api/KycForm/SubmitForm",
        data,
        function (response) {
          if (response.statusCode == 200) {
            comp.kyc_form_id = response.data.kyc_form_id;
            comp.referenceNumber = response.data.reference_number;
            comp.piniaStorageHelper.clearLocalStorage(comp.kycFormStore);
            comp.isSubmitLoading = false;
            comp.$router.replace({
              path: "/congratulationsPage",
              query: { referenceNumber: comp.referenceNumber },
            });
          } else {
            comp.isSubmitLoading = false;
            BuefyHelper.showToastMessage("Form submission failed", "is-danger");
          }
        },false,true);
    },
    uploadDocuments: function () {
      const comp = this;
      const formData = new FormData();
      formData.append("kyc_form_id", comp.kyc_form_id);
      formData.append(
        "nic_front_image",
        comp.kycFormStore.productImages.nic_front_image
      );
      formData.append(
        "nic_back_image",
        comp.kycFormStore.productImages.nic_back_image
      );
      formData.append(
        "passport_bio_page_image",
        comp.kycFormStore.productImages.passport_image
      );
      formData.append(
        "passport_alteration_page_image",
        comp.kycFormStore.productImages.passport_image_2
      );
      formData.append(
        "passport_entry_stamp_page_image",
        comp.kycFormStore.productImages.stamp_page_image
      );
      formData.append("visa_image", comp.kycFormStore.productImages.visa_image);
      formData.append(
        "selfie_image",
        comp.kycFormStore.productImages.selfie_image
      );
      formData.append(
        "signature_image",
        comp.kycFormStore.productImages.sign_image
      );
      formData.append(
        "address_proof_image",
        comp.kycFormStore.productImages.addr_proof_doc
      );
      formData.append("review_pdf", comp.kycFormStore.detailPDF.viewDetailPdf);

      formData.append("customer_email", comp.kycFormStore.formData.email);

      formData.append("reference_number", comp.referenceNumber);

      formData.append("customer_name", comp.kycFormStore.formData.calling_name);

      console.log(comp.referenceNumber);
      console.log(comp.kycFormStore.formData.calling_name);

      NetworkManager.apiRequest(
        "api/KycForm/uploadFormDocuments",
        formData,
        function (response) {
          if (response.statusCode === 200) {
            // clear pinia storage
            comp.piniaStorageHelper.clearLocalStorage(comp.kycFormStore);
            comp.isSubmitLoading = false;
            comp.$router.replace({
              path: "/congratulationsPage",
              query: { referenceNumber: comp.referenceNumber },
            });
          } else {
            comp.isSubmitLoading = false;
            BuefyHelper.showToastMessage(
              "Failed to upload the documents",
              "is-danger"
            );
          }
        }
      );
    },
  },
};
</script>
<style></style>
