<template>
  <div class="content-wrapper">
    <div class="columns mt-1">
      <div class="column img-after">
        <img
          :src=companyLogo
          alt="Company-ekyc-logo"
          class="company_ekyc_logo"
        />
      </div>
    </div>
    <!-- <div class="Lang_Select is-pulled-right">
      <span
        class="lang-button-style p-1 mr-3 language-button-bg"
        id="En"
        @click="changeLanguage(`en`)"
        >En</span
      >
      <span
        disabled
        class="lang-button-style p-1 mr-3"
        id="Si"
        @click="changeLanguage(`si`)"
        >සිං</span
      >
      <span
        class="lang-button-style p-1 px-2 mr-3"
        id="Ta"
        @click="changeLanguage(`ta`)"
        >த
      </span>
    </div> -->
  </div>
</template>
<script>
export default {
  data(){
    return{
      companyLogo: require("@/assets/images/company-logo.png"),
    }
  },
  methods: {
    // TODO :: use this later
    // eslint-disable-next-line no-unused-vars
    changeLanguage(lang) {
      //   const EnElement = document.getElementById("En");
      //   const SiElement = document.getElementById("Si");
      //   const TaElement = document.getElementById("Ta");
      //   this.$root.selectedLanguage = lang;
      //   if (lang === "en") {
      //     EnElement.classList.add("language-button-bg");
      //     SiElement.classList.remove("language-button-bg");
      //     TaElement.classList.remove("language-button-bg");
      //     this.kycFormStore.formData.selLang = "En";
      //     this.$root.selectedLanguageData = this.$root.engLanguageData;
      //   } else if (lang === "si") {
      //     EnElement.classList.remove("language-button-bg");
      //     SiElement.classList.add("language-button-bg");
      //     TaElement.classList.remove("language-button-bg");
      //     this.kycFormStore.formData.selLang = "සිං";
      //     this.$root.selectedLanguageData = this.$root.sinLanguageData;
      //   } else if (lang === "ta") {
      //     EnElement.classList.remove("language-button-bg");
      //     SiElement.classList.remove("language-button-bg");
      //     TaElement.classList.add("language-button-bg");
      //     this.kycFormStore.formData.selLang = "த";
      //     this.$root.selectedLanguageData = this.$root.tamLanguageData;
      //   }
    },
  },
};
</script>
<style scoped>
.content-wrapper {
  position: relative;
}

.lang-button-style {
  height: 30px;
  width: 30px;
  font-family: "Gotham-Light";
  border: solid 0.259px #696767;
  border-radius: 60%;
  transition: 0.7s;
  cursor: pointer;

  box-shadow: 2px 2px 3px #696767;
}

.lang-button-style:hover {
  background-color: #ed1c24;
  color: #ffffff;
  border: solid 0.259px #ed1c24;
  transform: translate(0, -9px);
  box-shadow: none;
}

.language-button-bg {
  background-color: #ed1c24 !important;
  border-color: #ed1c24;
  color: #ffffff;
}
@media only screen and (max-width: 336px) {
  .company_ekyc_logo {
    width: 58%;
    margin-bottom: 2.5%;
    margin-left: 2%;
  }
}
@media only screen and (min-width: 336px) {
  .company_ekyc_logo {
    width: 45%;
    margin-bottom: 2.5%;
    margin-left: 2%;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Lang_Select {
    margin-top: -15%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .company_ekyc_logo {
    width: 25%;
  }
  .img-after::after {
    content: "";
    position: absolute;
    width: 77%;
    height: 1px;
    background: #04c3fc;
    left: 19.8%;
    margin-top: 4.3%;
    z-index: -1;
  }
  .Lang_Select {
    margin-top: -13%;
    margin-right: 3%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .company_ekyc_logo {
    width: 20%;
  }
  .img-after::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    background: #04c3fc;
    left: 16.8%;
    margin-top: 3.75%;
    z-index: -1;
  }
  .Lang_Select {
    margin-top: -10.2%;
    margin-right: 3%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .company_ekyc_logo {
    width: 19%;
  }
  .img-after::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    background: #04c3fc;
    left: 16.8%;
    margin-top: 3.75%;
    z-index: -1;
  }
  .Lang_Select {
    margin-top: -8.5%;
    margin-right: 3%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .company_ekyc_logo {
    width: 19%;
  }
  .img-after::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    background: #04c3fc;
    left: 16.8%;
    margin-top: 3.75%;
    z-index: -1;
  }
}
</style>
