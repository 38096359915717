<template>
  <div>
    <div
        :style="{
        'border-radius': '10px',
        border: '1px solid black',
        padding: '10px',
        'background-color': '#f9f9f9',
        'text-align': 'center',
      }"
        class="img_component"
    >
      <a>
        <b-image
            :src="imageUrl === '' ? imagePlaceholder : imageUrl"
            placeholder="/static/img/icons/favicon-32x32.png"
            responsive
            class="mb-2 doc_image"
        ></b-image>
      </a>

      <p class="image-line mb-3" v-if="imageUrl === ''">
        <span style="color: #ed1c24; font-weight: 800">Note: </span>Maximum
        image size should be 5MB
      </p>

      <b-upload v-model="file" @input="onUpload" @change.native="removeImage" rounded :accept="allowPdf==false ? 'image/*' : '.pdf, image/*'">
        <a class="button is-primary">
          <span>{{ uploadButtonLabel }}</span>
        </a>
      </b-upload>
      <span class="mx-2" style="font-family: Gotham-Medium">or</span>
      <b-button type="is-primary" rounded @click="initiateImageCapture">
        {{ captureButtonLabel }}
      </b-button
      >
    </div>

    <!-- <b-modal v-model="isActive">
      <div class="modal-content" style="overflow: hidden">
        <div class="columns">
          <div class="column"></div>
          <div class="column">
            <section class="box px-6 py-5">
              <div class="buttons">
                <b-upload
                  v-model="file"
                  @input="onUpload"
                  expanded
                  accept="image/*"
                >
                  <a class="button is-primary is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>{{ uploadButtonLabel }}</span>
                  </a>
                </b-upload>
                <b-button
                  type="is-primary"
                  icon-left="camera-iris"
                  expanded
                  @click="initiateImageCapture"
                >
                  {{ captureButtonLabel }}</b-button
                >
              </div>
            </section>
          </div>
          <div class="column"></div>
        </div>
      </div>
    </b-modal> -->

    <b-modal
        v-model="captureModalIsVisible"
        has-modal-card
        full-screen
        :can-cancel="false"
    >
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body" style="padding: 0">
          <div class="columns">
            <div class="column"></div>
            <div class="column is-three-fifths">
              <div class="web-camera-container">
                <div v-show="isCameraOpen && isLoading" class="camera-loading">
                  <ul class="loader-circle">
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>

                <div
                    v-if="isCameraOpen"
                    v-show="!isLoading"
                    class="camera-box"
                    :class="{ flash: isShotPhoto }"
                >
                  <div
                      class="camera-shutter"
                      :class="{ flash: isShotPhoto }"
                  ></div>

                  <video
                      v-show="!isPhotoTaken"
                      ref="camera"
                      :width="450"
                      :height="337.5"
                      autoplay
                  ></video>

<!--                  <canvas-->
<!--                      v-show="isPhotoTaken"-->
<!--                      id="photoTaken"-->
<!--                      ref="canvas"-->
<!--                      :width="450"-->
<!--                      :height="337.5"-->
<!--                  ></canvas>-->

                  <!-- remove canvas width and height from the element itself-->
                  <canvas
                      v-show="isPhotoTaken"
                      id="photoTaken"
                      ref="canvas"
                  ></canvas>
                </div>
              </div>
            </div>
            <div class="column"></div>
          </div>
        </section>

        <footer class="modal-card-foot">
          <div id="video-call-div1"></div>
          <b-button
              v-if="isPhotoTaken"
              label="Done"
              class="is-rounded"
              type="is-info"
              @click="finishImageCapture"
          ></b-button>


          <b-button
          v-if="isPhotoTaken"
              label="Recapture"
              class="is-rounded"

              type="is-primary"
              @click="takePhoto"
          ></b-button>
          <b-button
          v-if="!isPhotoTaken"
              label="Capture"
              class="is-rounded"

              type="is-primary"
              @click="takePhoto"
          ></b-button>
          <b-button
              v-if="!isPhotoTaken"
              label="Back"
              class="is-rounded"
              type="is-info"
              @click="backToDocumentPage"
          ></b-button>
        </footer>
      </div>
    </b-modal>

    <b-modal
        v-model="cropModalIsVisible"
        has-modal-card
        full-screen
        :can-cancel="false"
    >
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body" style="padding: 0">
          <div class="columns">
            <div class="column"></div>
            <div class="column is-three-fifths">
              <div class="web-camera-container">
                <div class="img-cropper">
                  <vue-cropper
                      ref="cropper"
                      :aspect-ratio="crop_ratio"
                      :src="imageToCrop"
                      :minScale="0.5"
                      :guides="true"
                      :view-mode="3"
                      preview=".preview"
                  />
                </div>
              </div>
            </div>
            <div class="column"></div>
          </div>
        </section>

        <footer class="modal-card-foot">
          <div id="video-call-div1"></div>
          <b-button
              label="Done"
              type="is-primary"
              class="is-rounded"
              @click="cropImage"
          ></b-button>
          <b-button
              label="Cancel"
              type="is-info"
              class="is-rounded"
              @click="cropModalIsVisible = false"
          ></b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import {BuefyHelper} from "@/heplers/buefyHelper";
import {Attachment} from "@/heplers/classes/attachment/attachment";

export default {
  name: "ImageCaptureComponent",
  components: {VueCropper},
  props: [
    "crop_ratio",
    "file_name",
    "selectedImageURL",
    "uploadButtonLabel",
    "captureButtonLabel",
    "useFrontCamera",
    "allowPdf",
  ],
  emits: ["on-captured"],
  data() {
    return {
      imageUrl: "",
      isActive: false,
      captureModalIsVisible: false,
      cropModalIsVisible: false,
      imagePlaceholder: require("@/assets/images/icons/image_placeholder.png"),
      pdfPlaceholder: require("@/assets/images/icons/pdf.png"),
      file: null,
      imageToCrop: null,
      croppedImg: null,
      requestFrontCamera:false,

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      imagewidth: null,
      imageheight:null,
      link: "#",
      base64String: "",
    };
  },

  computed: {
    // imageUrl(){
    //   if(this.image_blob === null || this.image_blob === undefined){
    //     return this.imagePlaceholder
    //   }
    //   else {
    //     return URL.createObjectURL(this.image_blob)
    //   }
    // }
  },

  methods: {
    onUpload: function () {

      const comp = this
      if (comp.file !== null && comp.file["type"].split("/")[0] === "image") {

        console.log("uploaded image size (bytes): " + comp.file.size)
        // validate image size
        if (comp.file.size <= 5245329) {
          comp.imageToCrop = URL.createObjectURL(comp.file)
          comp.isActive = false;
          comp.initiateImageCrop();
        } else {
          comp.file = []
          BuefyHelper.showToastMessage("Please select an image of the maximum size of 5MB", "is-danger")
        }
      } 
      else if (comp.file !== null && comp.file["type"] == "application/pdf") {
        BuefyHelper.showToastMessage("You Choosed a PDF", "is-success")
        let attachment = new Attachment(comp.file,"pdf") ;
        this.imageUrl = this.pdfPlaceholder;
        this.$emit("on-captured", attachment); // test code
        // if (comp.file) {
        //     // Select the very first file from list
        //     var fileToLoad = comp.file;
        //     // FileReader function for read the file.
        //     var fileReader = new FileReader();
        //     var base64;
        //     // Onload of file read the file content
        //     fileReader.onload = function(fileLoadedEvent) {
        //         base64 = fileLoadedEvent.target.result;
        //         // Print data in console
        //         console.log(base64);
        //     };
        //     // Convert data to base64
        //     fileReader.readAsDataURL(fileToLoad);
        // }
      }
      // check if file is a pdf
      else {
        BuefyHelper.showToastMessage("Select an image or PDF file", "is-danger")
      }
    },
    removeImage:function(){
      // console.log("remove image")
      const comp = this

      if(comp.file !== null  && comp.file.size <= 5245329 ){
        comp.file = null
        console.log("image cleared")
      }
    },
    backToDocumentPage: function () {
      this.toggleCamera();
      this.captureModalIsVisible = false;
    },

    finishImageCapture: function () {
      this.toggleCamera();
      this.isActive = this.captureModalIsVisible = false;

      this.initiateImageCrop();
    },

    initiateImageCapture: function () {
      this.captureModalIsVisible = true;
      this.toggleCamera();
    },

    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        // video: true,
        video: {
          facingMode: this.requestFrontCamera ? 'user' :'environment'
        }
      });

      navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            alert("May the browser didn't support or there is some errors.");
          });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");


      // set canvas width and height based on video stream width and height
      this.$refs.canvas.width = this.$refs.camera.videoWidth
      this.$refs.canvas.height = this.$refs.camera.videoHeight

      //set image width and height based on video stream with and height
      // context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      context.drawImage(this.$refs.camera, 0, 0, this.$refs.camera.videoWidth, this.$refs.camera.videoHeight);

      this.imageToCrop = document
          .getElementById("photoTaken")
          .toDataURL("image/jpeg");
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document
          .getElementById("photoTaken")
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },

    initiateImageCrop: function () {
      this.cropModalIsVisible = true;
    },

    imageResize(image, maxWidth, maxHeight,dpi) {
      let width = maxWidth;
      let height = maxHeight;

      // if (width > maxWidth || height > maxHeight) {
      //   let aspectRatio = width / height;
      //
      //   if (width > height) {
      //     width = maxWidth;
      //     height = width / aspectRatio;
      //   } else {
      //     height = maxHeight;
      //     width = height * aspectRatio;
      //   }
      // }
      //
      const canvas = document.createElement("canvas");

      const getDpi = dpi;
      canvas.width = width * getDpi / 72; // 1 inch = 72 points
      canvas.height = height * getDpi / 72;

      const ctx = canvas.getContext("2d");
      ctx.scale(getDpi / 72, getDpi / 72);

      ctx.drawImage(image, 0, 0, width, height);


      console.log("New Canva width............................................................", canvas.width)
      console.log("New Canva height............................................................" , canvas.height)

      return canvas;

      // image.style.width = image.style.width || image.width + 'px';
      // image.style.height = image.style.height || image.height + 'px';
      //
      // // Get size information.
      // var scaleFactor = dpi / 96;
      // var width = parseFloat(maxWidth);
      // var height = parseFloat(maxHeight);
      //
      // // Backup the canvas contents.
      // var oldScale = maxWidth / width;
      // var backupScale = scaleFactor / oldScale;
      // var backup = image.cloneNode(false);
      // backup.getContext('2d').drawImage(image, 0, 0);
      //
      // // Resize the canvas.
      // var ctx = image.getContext('2d');
      // image.width = Math.ceil(width * scaleFactor);
      // image.height = Math.ceil(height * scaleFactor);
      //
      // // Redraw the canvas image and scale future draws.
      // ctx.setTransform(backupScale, 0, 0, backupScale, 0, 0);
      // ctx.drawImage(backup, 0, 0);
      // ctx.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);
      //
      //
      // return image

},


cropImage: function () {
      console.log("Heeeeee Inside crop image =====================================================================")
      const croppedImageCanvas = this.$refs.cropper.getCroppedCanvas();
      let attachment = null;

      this.croppedImg = croppedImageCanvas.toDataURL();


      this.cropModalIsVisible = false;
      // this.$emit('on-captured', this.croppedImg) // original code



      console.log("Canva width............................................................", croppedImageCanvas.width)
      console.log("Canva height............................................................" , croppedImageCanvas.height)
      console.log("Image name............................................................" , this.file_name)

      if((croppedImageCanvas.width > 500 ||  croppedImageCanvas.height > 500) && this.file_name === "sign_image"){

        console.log("I am here to crop the image ............................................................")
        this.imageUrl = this.imageResize(croppedImageCanvas, 380, 380,96).toDataURL();

      }else {
        console.log("No need to crop the image ............................................................")
        this.imageUrl = this.croppedImg;
      }
      // convert canvas into a blob
      var fileName = this.file_name + ".jpg";

      croppedImageCanvas.toBlob((blob) => {
        let file = new File([blob], fileName, {type: "image/jpeg"});
        attachment = new Attachment(file,"image") ;
        this.$emit("on-captured", attachment); // test code
      }, "image/jpeg");


    },

    setRequestFrontCamera(){
      const comp = this
      console.log("use Front camera prop : "+typeof comp.useFrontCamera)
      switch (typeof comp.useFrontCamera){
        case 'boolean':
          comp.requestFrontCamera = comp.useFrontCamera
              break
        default:
          comp.requestFrontCamera = false
      }
    },

  },
  watch : {
    "imageUrl":function(){
      console.log("image url changed : " + this.imageUrl)
    }
  },
  mounted() {
    console.log("Selected image URL : " + this.selectedImageURL);
    this.imageUrl = this.selectedImageURL == null ? "" : this.selectedImageURL;
    this.setRequestFrontCamera()



  },
};
</script>

<style scoped>
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.web-camera-container .camera-button {
  margin-bottom: 2rem;
}

.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  width: 90%;
  height: auto;
  background-color: #fff;
  position: absolute;
}

.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}

.web-camera-container .camera-shoot {
  margin: 1rem 0;
}

.web-camera-container .camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.web-camera-container .camera-shoot button img {
  height: 35px;
  object-fit: cover;
}

.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}

.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}

.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}

.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

/* cropper styles */

.crop-content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}

.modal-card {
  border-radius: 0;
}
</style>
