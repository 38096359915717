<template>
  <div class="get-help get-help-chat-bot">
        <div class="is-flex is-justify-content-center is-flex-direction-column ">
            <img
            :src=getHelpImage
            alt="gethelp image"
            class="gethelp_image ml-2"
            />
            <p class="has-text-primary  mt-0 pt-0 is-size-6-desktop" style="font-family: Gotham-Medium">
            Get Help
            </p>
        </div>        
    </div>
</template>
<script>
import $ from "jquery";
import chatBot from "@/heplers/chatBot";

export default {
  data() {
    return {
      getHelpImage : require("@/assets/images/icons/Get_help.png")
      // isDragging: false,
      // position: { x: 37, y: 40 },
      // startPosition: { x: 0, y: 0 },
    };
  },

  methods: {
  },
  mounted() {
    // Using jQuery ============================================
    $(function () {
      // eslint-disable-next-line no-undef

      new chatBot("get-help-chat-bot");
    });
    // Using jQuery ============================================
  },
};
</script>
<style scoped>
.get-help {
    position: fixed;
   bottom: 5px;
    left: 92%;
    cursor: pointer;
  }

  .gethelp_image {
    width: 53px;
    display: block;
  }
  @media only screen and (max-width: 1169px) {
    .get-help {
    position: fixed;
   bottom: 5px;
    left: 88.5%;
    cursor: pointer;
  }}
  @media only screen and (max-width: 980px) {
    .get-help {
    position: fixed;
   bottom: 5px;
    left: 89%;
    cursor: pointer;
  }

  
}
  
</style>
