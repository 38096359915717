<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
    <logopage /></transition>
    <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
    <img
      src="../assets/images/icons/Arrow.png"
      alt="Arrow"
      width="40px"
      @click="$router.push('/')"
      style="cursor: pointer"
    />
  </transition>
    <transition appear
                @before-enter="$animations.beforeEnterBelow"
                @enter="$animations.enterBelow"
    >
    <div
      class="is-flex is-align-items-center is-justify-content-center height-style"
    >
      <div class="hero-div box">
        <div class="has-text-centered">
          <p class="hero-headline-font">
            {{ $root.selectedLanguageData.digital_onboarding }}
          </p>
          <p class="hero-subhead-line mb-4 mt-1" style="line-height: 18px">
            {{ $root.selectedLanguageData.application_status_check }}
          </p>
          <img
            src="../assets/images/welcome-page-hero.png"
            alt="welcome-page-hero"
            width="88%"
          />
          <p class="subhead-line mt-2 ">
            {{
              $root.selectedLanguageData
                .check_your_application_status_by_entering
            }}
          </p>
          <p class="has-text-centered   mb-4 astric-font-size">
                <span class="has-text-danger mr-2">{{
                  $root.selectedLanguageData.note
                }}</span><b class="has-text-danger">*</b><span class="text-transparency">
               Indicates mandatory fields that are required</span>
              </p> 
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="onSubmit">
            <div style="margin: auto" class="welcome-form">
              
              <div class="mb-2">
                <b-field
                  label-position="inside"
                  :custom-class="
                    kycFormStore.formData.customer_nic_eic_pp.trim() === '' &&
                    !isNicEicInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                  ><template #label
                    >{{ $root.selectedLanguageData.nic_eic_pp
                    }}<span class="has-text-danger">*</span></template
                  >
                  <p class="control has-icons-right">
                    <ValidationProvider
                      :rules="{
                        required: true,
                      }"
                      v-slot="{ errors }"
                    >
                      <b-input
                        rounded
                        v-model="kycFormStore.formData.customer_nic_eic_pp"
                        @focus="
                          () => {
                            isNicEicInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isNicEicInputFocus = false;
                          }
                        "
                        @input="kycFormStore.formData.customer_nic_eic_pp = kycFormStore.formData.customer_nic_eic_pp.toUpperCase()"
                      ></b-input>

                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <span class="icon is-small is-right">
                      <img
                        src="../assets/images/icons/nic.png"
                        alt="Call-img"
                        width="25"
                        height="25"
                      />
                    </span>
                  </p>
                </b-field>
              </div>

              <b-button
                type="is-primary"
                class="py-5 mt-5"
                expanded
                rounded
                :disabled="invalid"
                :loading="continueBtnIsLoading"
                @click="checkCustomerIsPresent"
                >{{ $root.selectedLanguageData.continue }}</b-button
              >
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div></transition>
    <!-- <GetHelp/> -->
  </div>
</template>
<script>
import logopage from "./CompanyTopLogo.vue";
// import GetHelp from "./GetHelp.vue";
import NetworkManager from "@/utils/networkManager";
import "../validate.js";
import { useKycFormStore } from "@/stores/KycFormStore";
import { BuefyHelper } from "@/heplers/buefyHelper";
import { GlobalFunctions } from "@/heplers/globalFunctions";

export default {
  /*
   * ===================================================================================================================
   * expose kyc form pinia store to the component
   */
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },

  components: {
    logopage,
    // GetHelp,
  },
  data() {
    return {
      isNicEicInputFocus: false,
      continueBtnIsLoading: false,
    };
  },
  mounted(){
    GlobalFunctions.clearLocalStorageToken();
  },
  methods: {

    checkCustomerIsPresent: function () {
      const comp = this;

      comp.continueBtnIsLoading = true;
      const data = {
        nic_passport_number: this.kycFormStore.formData.customer_nic_eic_pp,
      };
      NetworkManager.apiRequest(
        "api/CustomerPortal/checkCustomerIsPresent",
        data,
        function (response) {
          comp.continueBtnIsLoading = false;
          if (response.data.customer_available) {
            console.log("customer exists");
            comp.kycFormStore.formData.calling_name =
              response.data.calling_name;
            comp.kycFormStore.formData.email = response.data.email;
            comp.kycFormStore.formData.contact_no =
              response.data.contact_number;
            comp.kycFormStore.formData.customer_nic =
              response.data.customer_nic;

            comp.$router.push("otpPage");
          } else {
            BuefyHelper.showToastMessage(
              "No existing KYC forms were found for the given NIC/EIC/Passport Number",
              "is-danger"
            );
          }
        },false,true);
    },
  },
};
</script>
<style></style>
