export default function (btn) {

    window.addEventListener('load', function () {
        var st = document.createElement("link");
        var bu = "https://chatbot.paymedia.lk/paymedia_bot_new/";
        st.rel = "stylesheet";
        st.href = bu + "assets/widget/css/loops_styles.css";
        st.type = "text/css"
        document.head.appendChild(st);
        var holdyDiv = document.createElement("div");
        holdyDiv.setAttribute("id", "loops_chat");
        document.body.appendChild(holdyDiv)
        var ifm = document.createElement("iframe");
        ifm.setAttribute("id", "loops_frame");
        ifm.setAttribute("style", "height:1px;width:1px");
        ifm.setAttribute('src', bu + "Widget?op=la");
        holdyDiv.appendChild(ifm);

        window.addEventListener('message', function (event) {

            if (event.origin == "https://chatbot.paymedia.lk") {
                if (event.data.message == "OP") {
                    ifm.style.height = "489px";
                    ifm.style.width = "350px";
                    ifm.classList.add("chat-window");
                } else if (event.data.message == "CL") {
                    ifm.style.height = "1px";
                    ifm.style.width = "1px";
                    ifm.classList.remove("chat-window");
                }
            }

        }, false);

        var startBtns = document.getElementsByClassName(btn);
        console.log("buttons")
        console.log(startBtns)
        for (var i = 0; i < startBtns.length; i++) {
            startBtns[i].addEventListener("click", function () {
                console.log("clicked.....")
                ifm.contentWindow.postMessage("OP", "*")
            });
        }

    }, false);




}