<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
    <logopage /></transition>
    <transition appear
                @before-enter="$animations.beforeEnterBelow"
                @enter="$animations.enterBelow"
    >
    <div
      class="is-flex is-align-items-center is-justify-content-center height-style"
    >
      <div class="hero-div box has-text-centered">
        <img
          :src=charmCircle
          alt="charm_circle-tick.img"
          width="18%"
        />
        <p
          class="congrats-font-size mb-1"
          style="line-height: 45px; font-family: Gotham-Bold"
        >
          {{ $root.selectedLanguageData.congratulations }}
        </p>
        <p
          class="is-size-5-mobile is-size-4-tablet"
          style="font-family: Gotham-Bold"
        >
          {{ $root.selectedLanguageData.successfully_submitted }}
        </p>
        <p class="is-size-5 specific-sentance" style="color: #ed1c24">
          {{ $root.selectedLanguageData.reference_number }}
        </p>
        <div style="width: 50% !important; margin: auto">
          <div class="box mb-2">
            <p class="reference-num">
              {{ $route.query.referenceNumber }}
            </p>
          </div>
        </div>
        <div style="width: 80%; margin: auto; color: #696767" class="mt-3">
          {{ $root.selectedLanguageData.soft_copy_of_the_completed_aplication }}
        </div>
        <p class="is-size-4 mt-4" style="font-family: Gotham-Bold">
          {{ $root.selectedLanguageData.thankyou }}
        </p>
        <p
          style="font-family: Gotham-Light; width: 78%; margin: auto"
          class="is-size-6 mt-1"
        >
          {{
            $root.selectedLanguageData
              .yourdatahasbeensubmittedtooursystemoneofouragentswillconactyousoon
          }}
          <span
            class="link"
            @click="$router.replace('applicationStatusCheck')"
            :style="{
              'text-align': 'center',
              'text-justify': 'inter-word',
              'word-break': 'break-all',
              cursor: 'pointer',
              color: '#ed1c24',
              'font-family': 'Gotham-Book',
            }"
            >{{ statusCheckUrl }}
          </span>
        </p>

        <b-button
          type="is-primary"
          rounded
          expanded
          style="font-family: Gotham-Medium; width: 80%; margin: auto"
          class="py-5 mt-5 mb-5"
          @click="$router.replace('applicationStatusCheck')"
          >{{ $root.selectedLanguageData.done }}
        </b-button>
        <div class="columns mt-4">
         
          <div class="column has-text-centered">
            <img
              src="../assets/images/icons/Loan_cal_img.png"
              alt="loan_cal"
              width="30%"
            />
            <p class="mb-0 links_line">
              {{ $root.selectedLanguageData.loan_cal }}
            </p>
            <div class="mt-2">
              <b-button
                type="is-primary"
                rounded
                @click="goToCalculator()"
                style="font-family: Gotham-Medium"
              >
                Click to view
              </b-button>
            </div>
          </div>
          <div class="column border_box has-text-centered">
            <img
              src="../assets/images/icons/credit_card_img.png"
              alt="loan_cal"
              width="30%"
            />
            <p class="mb-0 links_line">
              {{ $root.selectedLanguageData.credit_card_promo }}
            </p>
            <div class="mt-2">
              <b-button
                type="is-primary"
                rounded
                @click="goTocreditCard()"
                style="font-family: Gotham-Medium"
                >Click to view
              </b-button>
            </div>
          </div>
          <div class="column">
            <img
              src="../assets/images/icons/tel_link_img.png"
              alt="loan_cal"
              width="30%"
            />
            <p class="add_space mb-0 links_line">
              {{ $root.selectedLanguageData.give_us_a_call }}
            </p>
            <div class="mt-2">
              <b-button
                type="is-primary"
                rounded
                style="font-family: Gotham-Medium"
                >011-2350000
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div></transition>
  </div>
</template>
<script>
import logopage from "./CompanyTopLogo.vue";

export default {
  components: {
    logopage,
  },
  name: "WelcomePage",
  data() {
    return {
      statusCheckUrl: "",
      charmCircle : require("@/assets/images/icons/charm_circle-tick.png"),
    };
  },
  mounted() {
    this.statusCheckUrl = window.location.origin + "/applicationStatusCheck";
    console.log("statusCheckUrl " + this.statusCheckUrl);
  },
  methods: {
    goToCalculator: function () {
      window.open("https://www.PayMedia.lk/calculators/", "_blank");
    },
    goTocreditCard: function () {
      window.open("https://www.PayMedia.lk/promotions/", "_blank");
    },
  },
};
</script>
<style>
.add_space {
  margin-bottom: 15%;
}
</style>
