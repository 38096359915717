var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"appear":""},on:{"before-enter":_vm.$animations.beforeEnterLogoArea,"enter":_vm.$animations.enterLogoArea}},[_c('logopage')],1),_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-center height-style"},[_c('div',{staticClass:"form-body"},[_c('div',{staticClass:"box has-text-centered"},[_c('p',{staticClass:"headline-font"},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.confirm_email)+" ")]),_c('p',{staticClass:"subhead-line",staticStyle:{"line-height":"20px"}},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.hi)+" "),_c('span',{style:({
              'text-justify': 'inter-word',
              'word-break': 'break-all',
              'font-family': 'Gotham-Medium',
              'margin-left': '0.5rem !important',
              'line-height': '20px',
            })},[_vm._v(_vm._s(_vm.kycFormStore.formData.calling_name || "Charith")+",")]),_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.we_sent_an_otp)+" ")]),_c('p',{staticClass:"specific-line mt-2 is-size-6",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.kycFormStore.formData.email || "XXXXX@gmail.com")+" ")]),_c('p',{staticClass:"specific-line mt-2 is-size-6",staticStyle:{"line-height":"22px"}},[_vm._v(" "+_vm._s(_vm.kycFormStore.formData.contact_no || "+9475XXXXXXX")+" ")]),_c('p',{staticClass:"has-text-centered mb-4 astric-font-size"},[_c('span',{staticClass:"has-text-danger mr-2"},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.note))]),_c('b',{staticClass:"has-text-danger"},[_vm._v("*")]),_c('span',{staticClass:"text-transparency"},[_vm._v(" Indicates mandatory fields that are required")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"mb-1 has-text-left box-div mt-5"},[_c('div',{staticClass:"mb-1 otp-font-size"},[_c('b-field',{attrs:{"label-position":"inside","custom-class":_vm.otp.trim() === '' && !_vm.isOtpInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$root.selectedLanguageData.please_enter_otp)),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('p',{staticClass:"control has-icons-right"},[_c('ValidationProvider',{attrs:{"name":"OTP","rules":{ length: 4, required: true, numeric: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-input',{attrs:{"rounded":"","maxlength":_vm.kycFormStore.formDataSupport.otpLength},on:{"focus":() => {
                            _vm.isOtpInputFocus = true;
                          },"blur":() => {
                            _vm.isOtpInputFocus = false;
                          }},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('span',{staticClass:"icon is-small is-right mr-1"},[_c('img',{attrs:{"src":_vm.otpImage,"alt":"otp-img","width":"30","height":"30"}})]),_c('span',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1),_c('div',{staticClass:"columns is-mobile mb-2"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"flex-start align"},[_c('b-field',[_c('b-radio',{attrs:{"native-value":true},model:{value:(_vm.kycFormStore.formData.mail_otp_not_receive),callback:function ($$v) {_vm.$set(_vm.kycFormStore.formData, "mail_otp_not_receive", $$v)},expression:"kycFormStore.formData.mail_otp_not_receive"}}),_c('p',{staticStyle:{"text-align":"start"}},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.didnot_receive)+" ")])],1)],1)]),_c('div',{staticClass:"column"},[_c('p',{staticStyle:{"text-align":"end"}},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.resend_otp_in)+" "),_c('span',{staticStyle:{"color":"#ed1c24","font-family":"Gotham-Medium"}},[_vm._v(_vm._s(_vm.countdownTime)+"s")])])])]),(_vm.kycFormStore.formData.mail_otp_not_receive)?_c('div',[_c('transition',{attrs:{"appear":""},on:{"before-enter":_vm.$animations.beforeEnterAbove,"enter":_vm.$animations.enterAbove}},[_c('p',{staticStyle:{"line-height":"35px"},on:{"click":function($event){return _vm.changeEmailId()}}},[_c('span',{staticClass:"link_style",staticStyle:{"color":"#ed1c24"}},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.click_here))]),_vm._v(_vm._s(_vm.$root.selectedLanguageData.difference_detail)+" ")])]),_c('transition',{attrs:{"appear":""},on:{"before-enter":_vm.$animations.beforeEnterAbove,"enter":_vm.$animations.enterAbove}},[_c('p',{class:_vm.countdownTime ? `otp_text` : ``,on:{"click":() => {
                        if (!_vm.countdownTime) {
                          _vm.resendOTP();
                        }
                      }}},[_c('span',{staticClass:"link_style mt-1",staticStyle:{"color":"#ed1c24"}},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.click_here))]),_vm._v(_vm._s(_vm.$root.selectedLanguageData.resend_otp)+" ")])])],1):_vm._e(),_c('transition',{attrs:{"appear":""},on:{"before-enter":_vm.$animations.beforeEnterBelow,"enter":_vm.$animations.enterBelow}},[_c('div',{staticClass:"is-flex is-flex-direction-row-reverse mt-4"},[_c('b-button',{staticClass:"is-primary is-rounded continue-button-en",attrs:{"disabled":invalid},on:{"click":function($event){return _vm.continueToNextPage()}}},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.continue)+" ")]),_c('b-button',{staticClass:"is-rounded back-button-en",on:{"click":function($event){return _vm.$router.push(_vm.$route.query.previous)}}},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.back)+" ")])],1)])],1)])]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }