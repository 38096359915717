var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box form-padding py-5"},[_c('p',{staticClass:"sub_headings has-text-centered"},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.product_selection)+" ")]),_c('p',{staticClass:"has-text-centered mb-4 note-line"},[_c('span',{staticClass:"has-text-danger has-text-weight-semibold"},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.note))]),_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.max_product)+" ")]),(
      _vm.kycFormStore.formData.product_selection[_vm.productSelectionId].account_type
    )?_c('p',{staticClass:"mb-3 account"},[_vm._v(" "+_vm._s(_vm.kycFormStore.formData.product_selection[_vm.productSelectionId].account_type)+" ")]):_vm._e(),_c('div',{staticClass:"columns is-4 is-variable"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{staticClass:"mb-0",attrs:{"label-position":"inside","custom-class":_vm.kycFormStore.formData.product_selection[_vm.productSelectionId]
              .currency_type === '' && !_vm.isCurrencyFocus
              ? 'custom-float-label-initial'
              : 'custom-float-label'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.currency))]},proxy:true}],null,true)},[_c('b-select',{class:_vm.kycFormStore.formData.product_selection[_vm.productSelectionId]
                .currency_type === '' && !_vm.isCurrencyFocus
                ? 'arrow-up-before'
                : 'arrow-up',attrs:{"rounded":"","expanded":""},on:{"focus":() => {
                _vm.isCurrencyFocus = true;
              },"blur":() => {
                _vm.isCurrencyFocus = false;
              }},nativeOn:{"change":function($event){return _vm.clearAccountTypeSelection()}},model:{value:(
              _vm.kycFormStore.formData.product_selection[_vm.productSelectionId]
                .currency_type
            ),callback:function ($$v) {_vm.$set(_vm.kycFormStore.formData.product_selection[_vm.productSelectionId]
                , "currency_type", $$v)},expression:"\n              kycFormStore.formData.product_selection[productSelectionId]\n                .currency_type\n            "}},_vm._l((_vm.filteredDataArr),function(currency){return _c('option',{key:currency.value,domProps:{"value":currency.value}},[_vm._v(" "+_vm._s(currency.langTranslation)+" ")])}),0)],1),_c('span',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"sub_topic_label"},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.accounts)),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(
              _vm.getFilteredProductList(
                _vm.kycFormStore.formData.product_selection[_vm.productSelectionId]
                  .currency_type === ''
                  ? ''
                  : _vm.kycFormStore.formData.product_selection[
                      _vm.productSelectionId
                    ].currency_type
              ).length > 0
            )?_c('div',{staticClass:"columns product-list"},_vm._l((_vm.getFilteredProductList(
                _vm.kycFormStore.formData.product_selection[_vm.productSelectionId]
                  .currency_type === ''
                  ? ''
                  : _vm.kycFormStore.formData.product_selection[
                      _vm.productSelectionId
                    ].currency_type
              )),function(product){return _c('div',{key:product.id,staticClass:"product-list-item"},[_c('b-field',[_c('b-radio',{staticClass:"en-style",attrs:{"name":_vm.productListGroupName,"native-value":product},nativeOn:{"change":function($event){return _vm.getSelectedProductDetails.apply(null, arguments)}},model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}},[_vm._v(" "+_vm._s(product.name)+" ")])],1)],1)}),0):_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedProduct),expression:"selectedProduct"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.selectedProduct)},on:{"input":function($event){if($event.target.composing)return;_vm.selectedProduct=$event.target.value}}})]),(errors[0])?_c('span',{staticClass:"has-text-danger"},[_vm._v("Please select your preferred product")]):_vm._e()]}}])}),_c('p',{staticClass:"mt-4 product-link-line"},[_c('span',{staticClass:"has-text-danger has-text-weight-semibold mr-2"},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.more_info))]),_c('a',{staticClass:"link",staticStyle:{"font-family":"Gotham-Book","color":"#231f20","text-decoration":"underline"},attrs:{"href":"https://www.PayMedia.lk/products-categories/personal/","target":"_blank"}},[_vm._v("https://www.PayMedia.lk/products-categories/personal/")])]),_c('div',{staticClass:"mt-4 product-link"},[_c('p',{staticClass:"has-text-danger has-text-weight-semibold mr-2"},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.more_info)+" ")]),_c('a',{staticClass:"link",staticStyle:{"font-family":"Gotham-Book","color":"#231f20","text-decoration":"underline"},attrs:{"href":"https://www.PayMedia.lk/products-categories/personal/","target":"_blank"}},[_vm._v("https://www.PayMedia.lk/products-categories/personal/")])])],1),_c('br'),_c('hr',{style:({
          'background-color': '#1b1b1b',
          height: '1.8px',
          opacity: '0.5',
          'margin-top': '0',
        })}),_c('div',[_c('label',{staticClass:"sub_topic_label"},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.purpose_of_account_opening)),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"columns purposes-list"},_vm._l((_vm.$root.selectedLanguageData
                .purposeList),function(purpose,index){return _c('div',{key:index,staticClass:"purpose-list-item"},[_c('b-field',[_c('b-checkbox',{staticClass:"en-style",attrs:{"native-value":new _vm.PurposeOfAccountOpening(purpose.key, purpose.value)},model:{value:(
                    _vm.kycFormStore.formData.product_selection[
                      _vm.productSelectionId
                    ].purpose_of_opening_account
                  ),callback:function ($$v) {_vm.$set(_vm.kycFormStore.formData.product_selection[
                      _vm.productSelectionId
                    ], "purpose_of_opening_account", $$v)},expression:"\n                    kycFormStore.formData.product_selection[\n                      productSelectionId\n                    ].purpose_of_opening_account\n                  "}},[_vm._v(" "+_vm._s(purpose.langTranslation)+" ")])],1)],1)}),0),_c('span',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.IsPurposeOfOpeningOtherSelected)?_c('div',[_c('div',[_c('b-field',{attrs:{"label-position":"inside","custom-class":_vm.specified_purpose_of_opening.trim() === '' &&
              !_vm.isSpecifiedPurposeInputFocus
                ? 'custom-float-label-initial'
                : 'custom-float-label'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$root.selectedLanguageData.please_specify)),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}],null,false,416912946)},[_c('p',{staticClass:"control has-icons-right"},[_c('ValidationProvider',{attrs:{"name":"Purpose","rules":_vm.validatePurpose()},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-input',{attrs:{"rounded":"","type":"text"},on:{"focus":() => {
                      _vm.isSpecifiedPurposeInputFocus = true;
                    },"blur":() => {
                      _vm.isSpecifiedPurposeInputFocus = false;
                    }},nativeOn:{"change":function($event){return _vm.addSpecifiedPurpose.apply(null, arguments)}},model:{value:(_vm.specified_purpose_of_opening),callback:function ($$v) {_vm.specified_purpose_of_opening=$$v},expression:"specified_purpose_of_opening"}}),_c('span',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2447703817)}),_c('span',{staticClass:"icon is-small is-right"},[_c('img',{attrs:{"src":_vm.editImage,"alt":"contact-name-img","width":"25","height":"25"}})])],1)])],1)]):_vm._e(),_c('hr',{style:({
          'background-color': '#1b1b1b',
          height: '1.8px',
          opacity: '0.5',
          'margin-top': 4,
        })}),_c('div',{staticClass:"mt-5"},[_c('label',{staticClass:"sub_topic_label"},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.anticipated_volumes)+" "),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"columns volumes-list"},_vm._l((_vm.$root.selectedLanguageData
                .anticipatedVolumesList),function(volume,index){return _c('div',{key:index,staticClass:"volume-list-item"},[_c('b-field',[_c('b-radio',{staticClass:"en-style",attrs:{"name":_vm.anticipatedVolumesGroupName,"native-value":volume.value},model:{value:(
                    _vm.kycFormStore.formData.product_selection[
                      _vm.productSelectionId
                    ].anticipated_volume
                  ),callback:function ($$v) {_vm.$set(_vm.kycFormStore.formData.product_selection[
                      _vm.productSelectionId
                    ], "anticipated_volume", $$v)},expression:"\n                    kycFormStore.formData.product_selection[\n                      productSelectionId\n                    ].anticipated_volume\n                  "}},[_vm._v(" "+_vm._s(volume.langTranslation)+" ")])],1)],1)}),0),_c('span',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',[(!_vm.IsCurrencyIsLKR)?_c('p',{staticClass:"mb-4 mt-5 note-line"},[_c('span',{staticClass:"has-text-danger has-text-weight-semibold"},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.note))]),_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.fcy_initial_deposit_note)+" ")]):_vm._e(),_c('ValidationProvider',{attrs:{"rules":{
            required: _vm.IsCurrencyIsLKR,
            validateInitialDeposit: _vm.IsCurrencyIsLKR,
            validateInitialDepositFC: !_vm.IsCurrencyIsLKR,
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{attrs:{"label-position":"inside","custom-class":_vm.initial_deposit.trim() === '' && !_vm.isInitialDepositInputFocus
                ? 'custom-float-label-initial'
                : 'custom-float-label'},scopedSlots:_vm._u([(_vm.IsCurrencyIsLKR)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.initial_deposit)+" "+_vm._s(_vm.$root.selectedLanguageData.commas)+" "+_vm._s(_vm.kycFormStore.formData.product_selection[_vm.productSelectionId] .currency_type)),_c('span',{staticClass:"has-text-danger"},[_vm._v("*")])]},proxy:true}:{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.initial_deposit)+" "+_vm._s(_vm.$root.selectedLanguageData.commas)+" "+_vm._s(_vm.kycFormStore.formData.product_selection[_vm.productSelectionId] .currency_type)),_c('span',{staticClass:"has-text-danger"})]},proxy:true}],null,true)},[_c('p',{staticClass:"control has-icons-right"},[_c('b-input',{attrs:{"rounded":""},on:{"focus":() => {
                    _vm.isInitialDepositInputFocus = true;
                  },"blur":() => {
                    _vm.isInitialDepositInputFocus = false;
                  },"input":function($event){return _vm.formatInitialDepositAmount()}},model:{value:(_vm.initial_deposit),callback:function ($$v) {_vm.initial_deposit=$$v},expression:"initial_deposit"}}),_c('span',{staticClass:"icon is-small is-right"},[_c('img',{attrs:{"src":_vm.editImage,"alt":"contact-name-img","width":"25","height":"25"}})]),_c('span',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}])})],1),(_vm.IsAbleToAddNewProduct)?_c('div',{staticClass:"box mt-5 has-text-centered"},[(!_vm.IsNextProductSelectionAvailable)?_c('div',{style:({
            cursor: 'pointer',
            color: '#F57E82',
            'line-height': '20px',
            'letter-spacing': '0.7px',
          })},[_c('p',{on:{"click":_vm.addNewProductSelection}},[_c('span',{staticClass:"icon has-text-info mr-2"},[_c('img',{attrs:{"src":_vm.criclePlus,"alt":"Circle_Plus image"}})]),_c('span',{staticClass:"is-size-6-mobile mt-1"},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.select_another_acc)+" ")])])]):_vm._e(),(_vm.IsNextProductSelectionAvailable)?_c('div',{style:({
            cursor: 'pointer',
            color: '#F57E82',
            'line-height': '20px',
            'letter-spacing': '0.7px',
          })},[_c('p',{on:{"click":_vm.removeNextProductSelection}},[_c('span',{staticClass:"icon has-text-info mr-2"},[_c('img',{attrs:{"src":_vm.circleMinus,"alt":"Circle_Mini image"}})]),_c('span',{staticClass:"is-size-6-mobile mt-1"},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.remove_account_name))])])]):_vm._e()]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }